import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { QrCodeService } from '../qr-input-service/qr-input.service';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject } from 'rxjs/internal/Subject';
import { PcSpecsService } from '../../../modules/details-page/services/pc-specs/pc-specs.service';
import { MessageService } from '../message-service/message.service';

@Injectable({
    providedIn: 'root'
})

export class IdleService {
    elapseTime: number = 120; // currently hard coded for 2mins
    private ngUnsubscribe = new Subject<boolean>(); //IIT2-3585
    isPlaying : boolean;

    constructor(
        private idle: Idle,
        private router: Router,
        private qrCodeService: QrCodeService,
        private pcSpecsService: PcSpecsService,
        private messageService: MessageService
    ) { }

    onUserIdle(): void {
        if (this.idle.isRunning()) {
            this.idle.stop();
        }
        this.idle.setIdle(this.elapseTime);
        this.idle.setTimeout(Infinity);
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
        //IIT2-3585 starts
        this.qrCodeService.isVideoPlaying.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data)=>
        { 
            this.isPlaying = data;
        });
        this.idle.onIdleStart.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
                    if (this.isPlaying) {
                        this.resetIdle();
                    } else {
                     this.qrCodeService.resetQrInput();
                     this.pcSpecsService.setCurrentHighlightFactTag('');
                     this.messageService.setPreviousPage([]);
                     this.router.navigateByUrl('/home');
                    }
                });
        //IIT2-3585 ends
                // this.idle.onIdleEnd.subscribe(() => {
        // });
        this.idle.watch();
    }

    //IIT2-3585
    resetIdle() {
        this.idle.stop();
        this.idle.setIdle(this.elapseTime);
        this.idle.watch();
    }
}
