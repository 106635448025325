<virtual-assistant  *ngIf="isChatBotEnabled && !isAiIpos && (router.url != '/home') && (router.url != '/compare')" class="assist" class="position-fixed" style="z-index:1;right:0;"></virtual-assistant>    <!-- IIC-37 -->
<div id="routingUrl" *ngIf="(router.url != '/mobile') &&
 (router.url != '/home') &&
 (router.url != '/tutorial') &&
 (router.url != '/offline-tutorial') &&
 (router.url != '/compare') && (router.url != '/') && (router.url != '/bot')">
 <app-header *ngIf="isShowheader" [ngClass]="setFont()" [headerData]="passHeaderData" (backToHomePage)="getDetailPageData($event) " (detailScan)="detailscan($event)" (backtoPreviousPage)="passPreviousPageData($event)"></app-header></div>
 <div id="fontDirection" class="flex-grow-1 overflow-auto" [class.mtl-panel]=" isMTL && router.url==='/specs/processor' && !isMTLVpro" [ngClass]="setFont()" [dir]="direction">
<router-outlet></router-outlet>
</div>
<app-navigation-bar *ngIf="((router.url === '/compare') || (router.url === '/trending'))  && isAiIpos"></app-navigation-bar>
<div id="pcSpecs" *ngIf=" isPcSpecs && (router.url != '/compare') && (router.url != '/mob-content') && (router.url != '/home') && (router.url != '/all-specs') && (router.url != '/trending')" class="container pcSpecs-margin">
<app-pc-specs [getPreviousPageData]="sendPreviousPageData" [ngClass]="setFont()" [dir]="direction"></app-pc-specs>
</div>

<div class="loader_outer" *ngIf="isShowLoader">
    <span class="loader"></span>
</div>
