import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Params, Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';
import { QrCode } from './../../interfaces/qr-input.i';
import { v4 as uuidv4 } from 'uuid';
import { HttpMethodsService } from '../../../common/utility/http-methods.service';
import { MessageService } from '../../services/message-service/message.service';
import { FactTag } from '../../interfaces/fact-tag.i';
import { GlobalConstants } from '../../../common/constants/global.constant';
import { LibraryV2DataService } from '../library-v2/library-v2-data.service';
import { QrConstants } from './qr.constants';
import { DisplayConstants } from '../../../modules/details-page/services/display/display.consts';
import { environment } from '../../../../environments/environment';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { LibraryChrombookV2DataService } from '../library-chromebook-v2/library-chrombook-v2-data.service';
import { Utils } from '../../../common/utility/utils';
import { wifiFamilyValues } from '../../../common/constants/qrCodeMapping';

@Injectable({
  providedIn: 'root'
})
export class QrCodeService {
  qrInput: QrCode = {
    RetailerId: null,
    RetailerName:null,
    StoreId: null,
    DeviceId: null,
    Language: null,
    ContentType: null,
    ProcessorBadge: null,
    ProcessorDescription: null,
    GraphicsBadge: null,
    GraphicsDescription: null,
    Memory: null,
    Storage: null,
    Os: null,
    Price: null,
    Segment: null,
    StoreLogo: null,
    DeviceName: null,
    SessionId: null,
    SystemManufacturer: null,
    IsConnected: null,
    Wifi: null,
    TouchScreen: null,
    ScreenSize: null,
    DisplayType: null,
    Battery: null,
    Connectivity: null,
    ResolutionWidth: null,
    ResolutionHeight: null,
    MemoryTotal: null,
    DimenstionHeight: null,
    DimenstionWidth: null,
    CpuManufacture: null,
    DeviceType: null,
    WifiGeneration: null,
    WifiManufacture: null,
    UserId: null,
    OptaneMemory: null,
    WifiDisplay: null,
    WifiGenerationVarient: null,
    WifiFamily: null,
    VarientType: null,
    GraphicsMemory: null,
    CacheValue: null,
    ThreadValue: null,
    CoreValue: null,
    ClockSpeed: null,
    IsUnlocked: null,
    WifiModel: null,
    TotalMemory: null,
    Optane_Msg: null,
    OptaneMemorySize: null,
    ActualMemory: null,
    OSDescription: null,
    IsSSDAvailable: null,
    StoorageSSDValues: null,
    IsHDDAvailable: null,
    StorageHDDValues: null,
    IsStorageOptaneAvailable: null,
    StorageOptaneValues: null,
    SecondaryGraphicsCard: null,
    SecondaryGraphicsBadge: null,
    CpuDisplay: null,  // GLS-313766
    UnisonContent: null, // IJR-1736 12th genGaming
    VRContent: null, // IJR-1736 12th genGaming
    IsChatbotEnabled: null,
    PcoreCores: null,
    PcoreThreads: null,
    EcoreCores: null,
    EcoreThreads: null,
    DasTete: null,
    DasTronc: null,
    DasMembre: null,
    LegalVersion: null,
    Oled: null,
    CpuClockSpeed: null,
    SkuId: null,
    ApiId: null,
    VproEvo: null,
    LpecoreCores: null,
    LpecoreThreads: null
  };
  $qrDataSync: Observable<QrCode>;
  // Need replaysubject so data loads on first-time page load
  private qrDataSource = new ReplaySubject<QrCode>(1);
  enableRemote = false;
  renderer: Renderer2;
  remotefactTags: FactTag[] = [];
  libraryData = this.libraryServiceData.getLibraryV2Data('en-us');
  BASE_URL_ASSET = environment.apiBaseUrlAsset;
  comparePageFactTags = new BehaviorSubject<FactTag[]>([]);
  qrURLSource: string[] = [];
  initialScannedUrl: string;
  languageFamily = false;
  language: string;
  transformedDecimal: string;
  private graphicsCardDetails = new BehaviorSubject(false);
  currentGraphicsCard = this.graphicsCardDetails.asObservable();
  isChromeBook = false;
  storageValue: string;
  batteryDescriptionChatbot: string;
  displayDescriptionChatbot: string;
  connectivityDescriptionChatbot: string;
  DasTronc: any;
  DasMembre: any;
  DasTete: any;
  displayText: string;
  displayTextResolution: string;
  touchscreeenText: string;
  displayTextResolutionTouchscreen: string;
  isSingleGraphics: boolean = false; // IJR-3031
  //IIT2-3585 starts
  private videoPlaying = new BehaviorSubject(false);
  isVideoPlaying = this.videoPlaying.asObservable();
  //IIT2-3585 ends

  constructor(
    private router: Router,
    private httpService: HttpMethodsService,
    private msgService: MessageService,
    private libraryServiceData: LibraryV2DataService,
    private rendererFactory2: RendererFactory2,
    private librarychromebook: LibraryChrombookV2DataService,
  ) {
    this.$qrDataSync = this.qrDataSource.asObservable();
    this.renderer = rendererFactory2.createRenderer('undefined', undefined);
  }

  public setQrData(qrData: QrCode): void {
    this.qrDataSource.next(qrData);
    this.qrInput = qrData;
  }
  public getQrData = () => {
    return this.qrInput;
  }
  public getDeviceid = () => {
    return this.qrInput.DeviceId;
  }
  public getSessionId = () => {
    return this.qrInput.SessionId;
  }
  public resetQrInput(): void {
    this.resetConnection();
    this.enableRemote = false;
    // this.qrInput.SessionId = null;
    this.setBackgroundImage();  // IJR-1318
  }
  public setQrDataFromParams(params: Params, qrScanType: string): void {
    if (params['rt'] || params['qr']) {
      let values = decodeURI(params['rt']).split('~');
      this.isSingleGraphics = values[58] === GlobalConstants.SINGLE_GRAPHICS;
      if(!this.isSingleGraphics && values[7] === QrConstants.INTEL_IRIS_XE_MAX) {
        this.isSingleGraphics = true;
      }
      let qr: QrCode = {
        RetailerId: values[0],
        StoreId: values[1],
        DeviceId: values[2],
        Language: values[3],
        ContentType: this.setContentType(values),
        ProcessorBadge: values[5],
        ProcessorDescription: values[6],
        GraphicsBadge: values[7],
        GraphicsDescription: values[8],
        Memory: values[9],
        Storage: values[10],
        Os: values[11],
        Price: values[12] ? this.decodePrice(values[12]) : '',
        Segment: "#" + values[13],
        StoreLogo: '',
        DeviceName: values[15],
        IsConnected: values[16],
        Wifi: '',
        TouchScreen: values[18],
        DisplayType: values[19],
        ScreenSize: values[20],
        Battery: values[21],
        Connectivity: values[22],
        SystemManufacturer: values[23],
        ResolutionWidth: values[24],
        ResolutionHeight: values[25],
        MemoryTotal: values[26],
        DimenstionHeight: values[27],
        DimenstionWidth: values[28],
        CpuManufacture: this.setCpuManufacturer(values[29]),
        DeviceType: values[30],
        WifiGeneration: values[31],
        WifiManufacture: values[32].length > 1 ? values[32] : QrConstants.WIFI_MANUFACTURER[values[32]],
        UserId: values[33],
        OptaneMemory: values[34],
        WifiDisplay: this.setWifiDescription(values[35]),
        WifiGenerationVarient: values[36] || GlobalConstants.FACTTAG_DEFAULT_VALUE,
        WifiFamily: this.setWifiFamilyValues(values[37]),
        VarientType: values[38],
        GraphicsMemory: values[39],
        CacheValue: values[40],
        ThreadValue: values[41],
        CoreValue: values[42],
        ClockSpeed: values[43],
        IsUnlocked: values[44],
        WifiModel: values[45],
        TotalMemory: values[46],
        Optane_Msg: values[47],
        OptaneMemorySize: values[48],
        ActualMemory: values[49],
        OSDescription: this.setOSDescription(values[50]),
        IsSSDAvailable: values[51],
        StoorageSSDValues: values[52],
        IsHDDAvailable: values[53],
        StorageHDDValues: values[54],
        IsStorageOptaneAvailable: values[55],
        StorageOptaneValues: values[56],
        SecondaryGraphicsCard: values[57],
        SecondaryGraphicsBadge: values[58] === GlobalConstants.SINGLE_GRAPHICS ? '': values[58],
        CpuDisplay: values[59], // GLS-313766
        UnisonContent: values[60],
        VRContent: values[61], // IJR-1736 12th genGaming
        IsChatbotEnabled: values[62], // chatbot
        PcoreCores: values[63],
        PcoreThreads: values[64],
        EcoreCores: values[65],
        EcoreThreads: values[66],
        DasTronc: values[67],
        DasMembre: values[68],
        DasTete: values[69],
        LegalVersion: values[70],
        RetailerName: values[71],
        Oled: values[72],
        CpuClockSpeed: values[73],
        SkuId: values[74],
        ApiId: values[75],
        VproEvo: values[76],
        LpecoreCores: values[77],
        LpecoreThreads: values[78],
        SessionId: uuidv4(),
      };
      localStorage.setItem("retailerId",qr.RetailerId);
      localStorage.setItem("deviceId",qr.DeviceId);
      localStorage.setItem("retailerName",qr.RetailerName);
      localStorage.setItem("userId",qr.UserId);

      if (values[14]) {
        qr.StoreLogo = `${this.BASE_URL_ASSET}${QrConstants.S3_USERS}${values[33]}${QrConstants.SLASH}${QrConstants.S3_ASSET_LIBRARY}${QrConstants.SLASH}${QrConstants.S3_ASSET_LIBRARY}${QrConstants.HYPHEN}${values[33]}${QrConstants.S3_STORE_LOGOS}${values[14]}`;
      }
      if (qrScanType === GlobalConstants.INITIAL_SCAN) {
        this.isChromeBook = qr.ContentType[0] === 'C' ? true : false;
        this.qrURLSource.push(params['rt']);
        this.initialScannedUrl = params['rt'];
        this.setQrData(qr);
        this.setBackgroundImage();
        // this.loadPage();
        
        if (qr.IsChatbotEnabled?.toLocaleLowerCase().includes('s') || qr.IsChatbotEnabled?.toLocaleLowerCase().includes('y')) {
          window.dispatchEvent(new CustomEvent('OPENCHATBOT_EVENT', { detail: qr.IsChatbotEnabled?.toLocaleLowerCase() }));
        }
        qr.IsChatbotEnabled?.toLocaleLowerCase().includes('s') ?  this.router.navigateByUrl('/mob-content') : this.router.navigateByUrl('/home');  // IIC-37
        this.loadRemoteFactTag();
      } else if (qrScanType === GlobalConstants.DETAILS_SCAN) {
        this.isChromeBook = qr.ContentType[0] === 'C' ? true : false;
        this.setQrData(qr);
        this.setBackgroundImage();
        this.loadRemoteFactTag();
      } else {
        this.qrURLSource.push(params['rt']);
        this.setQrData(qr);
        this.loadRemoteFactTag();
      }
    }

  }
  public loadPage(): void {
    if (!this.qrInput.DeviceId) {
      this.router.navigateByUrl('/home');
    }
    else if ((this.qrInput.ContentType[0] !== 'C' && this.qrInput.IsConnected !== 'y') || (this.qrInput.ContentType === 'NI')) {
      this.router.navigateByUrl('/mobile');
    }
    else {
      this.validateSession();
    }
  }
  setBackgroundImage(): void {
    let backGroundImage;
    const body = this.renderer.selectRootElement(QrConstants.BODY, true);
    this.renderer.addClass(body, GlobalConstants.CHROMEBOOKBACKGROUND); // scrollbar-fix
    let backgroundImage;
    if (this.qrInput.ContentType[0] === 'S') {
      backGroundImage = GlobalConstants.STANDARDGEN11;
      if (this.qrInput.ContentType === GlobalConstants.STANDARDGEN12 || this.qrInput.ContentType === GlobalConstants.STANDARDGEN13 ||
        this.qrInput.ContentType === GlobalConstants.STANDARDGEN14) { // added 13th gen
        backGroundImage = GlobalConstants.STANDARDGEN12;
      }
      if(GlobalConstants.INTEL_PROCESSOR_BADGES.includes(this.qrInput?.ProcessorBadge)){
          backGroundImage = GlobalConstants.INTEL_INSIDE;
      }
      if(GlobalConstants.STD_MTL_BADGES?.includes(this.qrInput?.ProcessorBadge) || GlobalConstants.STD_LNL_BADGES.includes(this.qrInput?.ProcessorBadge)){
        backGroundImage = GlobalConstants.STANDARD_AI_IPOS;
      }      
    }
    else if (this.qrInput.ContentType[0] === 'G') {
      if (this.qrInput?.ContentType === GlobalConstants.GAMINGGEN13) {
        backGroundImage = GlobalConstants.GAMINGGEN13;
      } else if (this.qrInput?.ContentType === GlobalConstants.GAMINGGEN12) {
        backGroundImage = GlobalConstants.GAMINGGEN12;
      } if (this.qrInput?.ContentType === GlobalConstants.GAMINGGEN14) {
        backGroundImage = GlobalConstants.GAMINGGEN14;
        if(GlobalConstants.GAMING_1ST_GEN_ULTRA_BADGES?.includes(this.qrInput?.ProcessorBadge)){
          backGroundImage = GlobalConstants.GAMING_AI_IPOS;
        }
      } else {
        backGroundImage = GlobalConstants.GAMINGGEN11;
      }
    }
    else if (this.qrInput.ContentType === 'evo') {
      backGroundImage = GlobalConstants.EVO;
      if(GlobalConstants.EVO_MTL_BADGES?.includes(this.qrInput?.ProcessorBadge) || GlobalConstants.EVO_LNL_BADGES.includes(this.qrInput?.ProcessorBadge)){
        backGroundImage = GlobalConstants.EVO_AI_IPOS;
      }
    }
    else if (this.qrInput.ContentType === 'NI') {
      backGroundImage = GlobalConstants.NON_INTEL_TEXT;
    }
    else if (this.qrInput.ContentType[0] === 'R') {
      this.qrInput?.ContentType === GlobalConstants.CREATORGEN12|| this.qrInput?.ContentType === GlobalConstants.CREATORGEN13 ?
        backGroundImage = GlobalConstants.STANDARDGEN12 : backGroundImage = GlobalConstants.STANDARDGEN11;
      // this.renderer.addClass(body, GlobalConstants.CHROMEBOOKBACKGROUND); // creator scrollbar, re-using chrome
    }
    else {
      backGroundImage = GlobalConstants.STANDARDGEN11;
    }    
    if (this.qrInput.ContentType[0] === 'C') {    // II-3392
      this.renderer.setStyle(body, GlobalConstants.BACKGROUND, GlobalConstants.CHROMEBOOK_BACKGROUND);
    } else {
      if (this.qrInput?.ContentType === GlobalConstants.GAMINGGEN13) {
        this.renderer.addClass(body, GlobalConstants.GEN13GAMING);
      }
      backgroundImage = this.libraryServiceData.getBackgroundDetails(backGroundImage);
      this.renderer.setStyle(body, QrConstants.BACKGROUND_IMAGE, backgroundImage);
    }
  }

  public chromebackground(): void {
    this.isChromeBook = true;
    const body = this.renderer.selectRootElement(QrConstants.BODY, true);
    this.renderer.addClass(body, GlobalConstants.CHROMEBOOKBACKGROUND); // chromebook scrollbar
    this.renderer.setStyle(body, GlobalConstants.BACKGROUND, GlobalConstants.CHROMEBOOKWHITEBACKGROUND);
  }

  public resetConnection(): void {
    if (this.qrInput.DeviceId && this.qrInput.SessionId) {
      this.httpService.resetConnection(this.qrInput.SessionId, this.qrInput.DeviceId).subscribe(
        (res: any) => {
          if (res.status !== 200) {
            return
          }
        },
        (error: any) => {
          console.log("Reset Connection: " + JSON.stringify(error));
        });
    }
  }

  public updateConnectionId(): void {
    if (this.qrInput.DeviceId && this.qrInput.SessionId) {
      this.httpService.updateConnectionId(this.qrInput.SessionId, this.qrInput.DeviceId)
    }
  }

  public NotifyToDevice(): void {
    this.httpService.NotifyToDevice(this.qrInput.SessionId, this.qrInput.DeviceId, this.qrInput.SessionId).subscribe(
      (res: any) => {
        if (res.status !== 200) {
          this.msgService.sendMessage(res.body);
        }
      },
      (error: any) => {
        this.msgService.sendMessage(JSON.stringify(error));
      });
  }
  public validateSession(): boolean {
    let isValid = false;
    if (this.qrInput.DeviceId && this.qrInput.SessionId) {
      this.httpService.validateConnection(this.qrInput.SessionId, this.qrInput.DeviceId).subscribe(
        (res: any) => {
          if (res.status === 200) {
            isValid = true;
            this.enableRemote = true;
            this.NotifyToDevice();
            this.updateConnectionId()
            this.router.navigateByUrl('/remote');
          }
          else {
            this.msgService.sendMessage(res.body);
            this.router.navigateByUrl('/mobile');
          }
        },
        (error: any) => {
          this.msgService.sendMessage(error);
        });
    }
    return isValid;
  }
  private loadRemoteFactTag(): void {
    let facttag: FactTag;
    if (this.qrInput.ContentType[0] !== 'C') {
      this.libraryData = this.libraryServiceData?.getLibraryV2Data(this.qrInput.Language);
    } else {
      this.libraryData = this.librarychromebook?.getLibraryV2Data(this.qrInput.Language);
    }
    this.qrInput.GraphicsDescription= this.setGraphicsDesc(this.qrInput.GraphicsDescription);
    this.qrInput.SecondaryGraphicsCard = this.setGraphicsDesc(this.qrInput.SecondaryGraphicsCard);
    this.remotefactTags = [];
    if (this.qrInput.ContentType !== 'NI' && this.qrInput.ContentType[0] !== 'C') {
      if (this.qrInput.ProcessorBadge) {
        if (this.qrInput.ContentType[0] === 'R') {
          facttag = this.setCreatorProcessorFactTagData();
          this.remotefactTags.push(facttag);
        } else {
          facttag = this.setProcessorFactTagData();
          this.remotefactTags.push(facttag);
        }
      }
    } else if (this.qrInput.ContentType[0] === 'C') {
      if (this.qrInput.ProcessorBadge) {
        facttag = this.setChromebookProcessorFactTagData();
        this.remotefactTags.push(facttag);
      }
    }
    else {
      let processorDesc;
      if (this.qrInput.ProcessorBadge === GlobalConstants.AMD_PROC_BADGE || this.qrInput.ProcessorBadge === GlobalConstants.QUALCOMM_PROC_BADGE){
        processorDesc = this.qrInput.ProcessorDescription;
      }
      facttag = {
        name: GlobalConstants.PROCESSOR_NAME,
        title: this.libraryServiceData.getStaticText('tr_processorTitle'),
        description: processorDesc,
        homeTitle: this.libraryServiceData.getStaticText('tr_processorTitleCamel')
      };
      this.remotefactTags.push(facttag);
    }
    if (this.qrInput.ContentType[0] !== 'C') {
      if (this.qrInput.Oled === 'Y') {
        facttag = {
          name: GlobalConstants.SAMSUNGOLED,
          title: this.libraryServiceData.getStaticText(GlobalConstants.tr_samsung_oled),
          description: '',
          homeTitle: this.libraryServiceData.getStaticText(GlobalConstants.tr_samsung_oled),
        };
        this.remotefactTags.push(facttag);
      }
      if (this.qrInput.GraphicsDescription) {
        
        
        if ((this.qrInput?.GraphicsBadge === QrConstants.INTEL_IRIS_XE || this.qrInput?.GraphicsBadge === QrConstants.INTEL_IRIS_XE_MAX 
          || QrConstants.ARC_GRAPHICS.indexOf(this.qrInput?.GraphicsBadge)!=-1
          || (QrConstants.ARC_GRAPHICS.indexOf(this.qrInput?.GraphicsBadge)===-1 && this.qrInput?.GraphicsBadge[0] === 'A') || this.qrInput?.GraphicsBadge === QrConstants.BADGE_GIN012 || this.qrInput?.GraphicsBadge === QrConstants.BADGE_GIN013) &&
          (this.qrInput?.ContentType === GlobalConstants.STANDARDGEN11 || this.qrInput?.ContentType === GlobalConstants.GAMINGGEN11 ||
            this.qrInput?.ContentType === GlobalConstants.GAMINGGEN12 || this.qrInput?.ContentType === GlobalConstants.STANDARDGEN12 || // IJR-2405
            this.qrInput?.ContentType === GlobalConstants.GAMINGGEN13 || this.qrInput?.ContentType === GlobalConstants.STANDARDGEN13 ||
            this.qrInput?.ContentType === GlobalConstants.STANDARDGEN14 || // 13th gen
            this.qrInput?.ContentType === GlobalConstants.GAMINGGEN14|| this.qrInput?.ContentType === GlobalConstants.CREATORGEN11|| this.qrInput?.ContentType === GlobalConstants.CREATORGEN12|| this.qrInput?.ContentType === GlobalConstants.CREATORGEN13|| 
            this.qrInput?.ContentType === GlobalConstants.EVO || (( QrConstants.ARC_GRAPHICS.indexOf(this.qrInput?.GraphicsBadge)!=-1||
            (QrConstants.ARC_GRAPHICS.indexOf(this.qrInput?.GraphicsBadge)===-1 && this.qrInput?.GraphicsBadge[0] === 'A'))  &&
              (this.qrInput?.ContentType === GlobalConstants.CREATORGEN11 || this.qrInput?.ContentType === GlobalConstants.CREATORGEN12 
              || this.qrInput?.ContentType === GlobalConstants.CREATORGEN13)))) {
          facttag = this.setGraphicsFactTagData();
          this.remotefactTags.push(facttag);
        } else {
          if(!this.isAppGaming() && this.qrInput.ContentType[0] != 'R'){
            facttag = {
              name: GlobalConstants.GRAPHICS_NAME,
              title: this.libraryServiceData.getStaticText('tr_graphicTitle'),
              description: this.qrInput.GraphicsDescription,
              homeTitle: this.libraryServiceData.getStaticText('tr_graphicTitleCamel'),
            };
            this.remotefactTags.push(facttag);              
          }
          else{
            facttag = {
              name: GlobalConstants.GRAPHICS_NAME,
              title:  this.libraryServiceData.getStaticText('tr_graphicTitle'),
              description: this.qrInput.GraphicsDescription,
              homeTitle: this.libraryServiceData.getStaticText('tr_graphicTitleCamel')
            };
            this.remotefactTags.push(facttag);
          }
        }
      }
      // IJR-98 MULTIPLE GRAPHICS CARD START
      if (this.qrInput.SecondaryGraphicsCard) {
        if ((this.qrInput.SecondaryGraphicsBadge === QrConstants.INTEL_IRIS_XE_MAX) &&
          (this.qrInput.ContentType === GlobalConstants.STANDARDGEN11 || this.qrInput.ContentType === GlobalConstants.STANDARDGEN12 ||
            this.qrInput.ContentType === GlobalConstants.STANDARDGEN13 || this.qrInput.ContentType === GlobalConstants.EVO)) {
          facttag = this.setSecondaryGraphicsFactTagData();
          this.remotefactTags.push(facttag);
        } else if((QrConstants.ARC_GRAPHICS.indexOf(this.qrInput?.SecondaryGraphicsBadge)!==-1 ||
                (QrConstants.ARC_GRAPHICS.indexOf(this.qrInput?.SecondaryGraphicsBadge)===-1 && this.qrInput?.SecondaryGraphicsBadge[0] === 'A'))) {
                  facttag = this.setSecondaryGraphicsFactTagData();
                  this.remotefactTags.push(facttag);
        }
        else {
          let secondaryGraphicsTitle = this.libraryServiceData.getStaticText('tr_graphicTitle')
          let secondaryGraphicsName = GlobalConstants.GRAPHICS_CARD;
          let homeTitles = this.libraryServiceData.getStaticText('tr_graphicTitleCamel');
          if (this.qrInput.ContentType[0] === 'G' || this.qrInput.GraphicsDescription === '') {
            secondaryGraphicsTitle = this.libraryServiceData.getStaticText('tr_graphicTitle');
            secondaryGraphicsName = GlobalConstants.GRAPHICS_NAME;
            homeTitles = this.libraryServiceData.getStaticText('tr_graphicTitleCamel');
          }

          facttag = {
            name: secondaryGraphicsName,
            title: secondaryGraphicsTitle,
            description: this.qrInput.SecondaryGraphicsCard,
            homeTitle: homeTitles
          };
          this.remotefactTags.push(facttag);
        }
      }
      // IJR-98 MULTIPLE GRAPHICS CARD END

      if (this.qrInput?.TotalMemory) {
        facttag = {
          name: GlobalConstants.MEMORY_NAME,
          title: this.libraryServiceData.getStaticText('tr_memoryTitle'),
          description: this.setMemoryDescription(), // this.libraryServiceData.getStaticText('tr_memoryValue').replace('[XX]', this.qrInput?.TotalMemory),
          homeTitle: this.libraryServiceData.getStaticText('tr_memoryTitleCamel')
        };
        this.remotefactTags.push(facttag);
      }

      if (this.qrInput.Battery && Number(this.qrInput.Battery) > 0) {
        const description = this.libraryServiceData.getStaticText('tr_batteryHoursText').replace(GlobalConstants.XX, this.transformDecimal(this.qrInput.Battery))
        this.batteryDescriptionChatbot = description; // battery description chatbot
        facttag = {
          name: GlobalConstants.BATTERY_NAME,
          title: this.libraryServiceData.getStaticText('tr_batteryTitle'),
          description: description,
          homeTitle: this.libraryServiceData.getStaticText('tr_batteryTitleCamel'),
        };
        this.remotefactTags.push(facttag);
      }
      // GLS-301410 start

      if (this.qrInput.DisplayType === DisplayConstants.SCREEN_DCI_4K || this.qrInput.DisplayType === DisplayConstants.SCREEN_UHD_4K) { // IJR-1849
        this.qrInput.DisplayType = DisplayConstants.SCREEN_4K;
      } // IJR-1811
      if ((Number(this.qrInput.ResolutionWidth) * Number(this.qrInput.ResolutionHeight) >= DisplayConstants.SCREEN_RESOLUTION_MILLION)) {
        // Moved all display related code to getDisplayDescription() function
        const displayDescription = this.getDisplayDescription(GlobalConstants.QR_SERVICE);
        this.displayDescriptionChatbot = displayDescription;  //display description chatbot
        facttag = {
          name: GlobalConstants.DISPLAY_NAME,
          title: this.libraryServiceData.getStaticText('tr_displayTitle'),
          description: displayDescription,
          homeTitle: this.libraryServiceData.getStaticText('tr_displayTitleCamel'),
        };
        this.remotefactTags.push(facttag);
      }

      if (this.qrInput.Os !== QrConstants.SUPPRESS_WIN_OS && this.qrInput.Os !== QrConstants.SUPPRESS_OTHER_OS && this.qrInput.Os !== QrConstants.UWP) {
        facttag = {
          name: GlobalConstants.OPERATING_SYSTEM_NAME,
          title: this.libraryServiceData.getStaticText('tr_operatingSystemTitle'),
          description: this.qrInput.OSDescription,
          homeTitle: this.libraryServiceData.getStaticText('tr_operatingSystemTitleCamel'),
        };
        this.remotefactTags.push(facttag);
      }
      if (Number(this.qrInput?.StoorageSSDValues) > 0 || Number(this.qrInput?.StorageHDDValues) > 0 ) {
        facttag = {
          name: GlobalConstants.STORAGE_NAME,
          title: this.libraryServiceData.getStaticText('tr_storageTitle'),
          description: this.rethinkStorageDescription(),
          homeTitle: this.libraryServiceData.getStaticText('tr_storageTitleCamel'),
        };
        this.remotefactTags.push(facttag);
      }
      if (this.qrInput.OptaneMemory) {
        let optaneDesc = this.qrInput.OptaneMemory + ' ' + this.libraryServiceData.getStaticText(GlobalConstants.GB_STATIC_TEXT);
        if (Number(this.qrInput.OptaneMemory) >= 1000) {
          const tbVlaue = Number((Number(this.qrInput.OptaneMemory) / 1000).toFixed(1));
          optaneDesc = tbVlaue + ' ' + this.libraryServiceData.getStaticText(GlobalConstants.TB_STATIC_TEXT)
        }
        facttag = {
          name: GlobalConstants.OPTANE_MEMORY,
          title: this.libraryServiceData.getStaticText('tr_IntelOptaneMemory'),
          description: optaneDesc,
          homeTitle: this.libraryServiceData.getStaticText('tr_optaneMemoryTitle'),
        };
        this.remotefactTags.push(facttag);
      }

      if (this.qrInput.Connectivity) {
        let thunderboltValue = '';
        if (this.qrInput.Connectivity === GlobalConstants.THUNDERBOLD_3) {
          thunderboltValue = this.libraryServiceData.getStaticText('tr_Thunderbolt3');
        } else if (this.qrInput.Connectivity === GlobalConstants.THUNDERBOLD_4) {
          thunderboltValue = this.libraryServiceData.getStaticText('tr_Thunderbolt4');
        }
        this.connectivityDescriptionChatbot = thunderboltValue; //chatbot connectivity value
        facttag = {
          name: GlobalConstants.CONNECTIVITY_NAME,
          title: this.libraryServiceData.getStaticText('tr_connectivityTitle'),
          description: thunderboltValue,
          homeTitle: this.libraryServiceData.getStaticText('tr_connectivityTitleCamel'),
        };
        this.remotefactTags.push(facttag);
      }
      if (this.qrInput?.WifiDisplay) {
        const wifiDescription = this.qrInput?.WifiDisplay;
        facttag = {
          name: GlobalConstants.WIFI_NAME,
          title: this.libraryServiceData.getStaticText('tr_wifiTitle'),
          description: wifiDescription, // UAT-291
          homeTitle: this.libraryServiceData.getStaticText('tr_wifiTitle'),
        };
        this.remotefactTags.push(facttag);
      }

      if (this.qrInput.DasTete || this.qrInput.DasTronc || this.qrInput.DasMembre) {
        this.remotefactTags.push(this.getDasFactTag());
      }
    } else {
      // changed logic for story IJR-2382
      if (Number(this.qrInput?.StoorageSSDValues) > 0 || Number(this.qrInput?.StorageHDDValues) > 0 ) {
        const storageDescription  = this.rethinkStorageDescription();
        facttag = {
          name: GlobalConstants.RETHINKSTORAGE,
          title: this.librarychromebook.getStaticText('tr_rethinkStorageTitle'),
          description: storageDescription,
          homeTitle: this.librarychromebook.getStaticText('tr_rethinkStorage'),
        };
        this.remotefactTags.push(facttag);
      } else {
        facttag = {
          name: GlobalConstants.RETHINKSTORAGE,
          title: this.librarychromebook.getStaticText('tr_rethinkStorageTitle'),
          description: '',
          homeTitle: this.librarychromebook.getStaticText('tr_rethinkStorage'),
        };
        this.remotefactTags.push(facttag);
      }
      if (this.qrInput.IsConnected[0] === 'Y') { // IJR - 1281
        facttag = {
          name: GlobalConstants.ALWAYSONSECURITY,
          title: this.librarychromebook.getStaticText('tr_alwaysOnSecurityTitle'),
          description: '',
          homeTitle: this.librarychromebook.getStaticText('tr_alwaysOnSecurity'),
        };
        this.remotefactTags.push(facttag);
      }

      if (this.qrInput.Battery) {
        facttag = {
          name: GlobalConstants.EXTENDEDBATTERYLIFE,
          title: this.librarychromebook.getStaticText('tr_extendedBatteryLifeTitle'),
          description: this.librarychromebook.getStaticText('tr_extendedBatteryLifeDescription')
            .replace(GlobalConstants.XX, this.transformDecimal(this.qrInput.Battery)),
          homeTitle: this.librarychromebook.getStaticText('tr_extendedBatteryLife'),
        };
        this.remotefactTags.push(facttag);
      }
      if (this.qrInput.IsConnected[1] === 'Y') { // IJR - 1218
        facttag = {
          name: GlobalConstants.ENDLESSPROGRAMSANDAPPS,
          title: this.librarychromebook.getStaticText('tr_endlessProgramsAndAppsTitle'),
          description: '',
          homeTitle: this.librarychromebook.getStaticText('tr_endlessProgramsAndApps'),
        };
        this.remotefactTags.push(facttag);
      }
      if (this.qrInput.DasTete || this.qrInput.DasTronc || this.qrInput.DasMembre) {
        this.remotefactTags.push(this.getDasFactTag());
      }
    }
  }
  public getRemoteFactTag(): FactTag[] {
    return this.remotefactTags;
  }

  public decodePrice(val) {
    let str = decodeURIComponent(escape(window.atob(val)));
    return str;
  }

  setProcessorFactTagData(): FactTag {
    let facttag;
    let details;
    const processorBadge = this.qrInput.ProcessorBadge;
    let processorDescriptionTitle = this.qrInput.ProcessorDescription;
    let cpuFamily;
    if (processorBadge === GlobalConstants.INT095) {
      cpuFamily = QrConstants[GlobalConstants.INT075];
    } else if (processorBadge === GlobalConstants.INT096) {
      cpuFamily = QrConstants[GlobalConstants.INT076];
    } else if (processorBadge === GlobalConstants.INT097) {
      cpuFamily = QrConstants[GlobalConstants.INT077];
    } else {
      cpuFamily = QrConstants[processorBadge];
    }
    const isBelowGen = processorBadge.split('_');
    if (isBelowGen.length === 2) {
      const changeGen = Number(isBelowGen[1]) - 2;
      const newbadge = isBelowGen[0] + '_' + changeGen;
      const cpuBelow = 'INTEL_CORE_' + newbadge;
      details = this.libraryServiceData.getFactTagsDetails(QrConstants.BELOW_7TH_GEN, GlobalConstants.PROCESSOR_NAME, GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
      if (details?.badgeUrl) {
        details.badgeUrl = details.badgeUrl.replace('[cup_badge]', cpuBelow);
      }
    }
  
    else {
      if (this.qrInput.ContentType === GlobalConstants.GAMINGGEN11 ||
        this.qrInput.ContentType === GlobalConstants.GAMINGGEN9) { // Added GAMINGGEN9 condition for 9GenGaming mobile stories: IJR-154 Epic
        details = this.libraryServiceData.getFactTagsDetails(cpuFamily, GlobalConstants.PROCESSOR_NAME, 'GAMING', 'DEFAULT');
      } else if (this.qrInput.ContentType === GlobalConstants.GAMINGGEN10) {
        const deviceType = (this.qrInput.DeviceType === GlobalConstants.DEVICE_TYPE_DESKTOP) ?
          GlobalConstants.DESKTOP_NAME : GlobalConstants.LAPTOP_NAME;
        details = this.libraryServiceData.getFactTagsDetails(cpuFamily, GlobalConstants.PROCESSOR_NAME, GlobalConstants.GAMING, deviceType);
      } else if (this.qrInput.ContentType === GlobalConstants.GAMINGGEN12) {
        if (cpuFamily === QrConstants.INT099 || cpuFamily === QrConstants.INT100 ||
          cpuFamily === QrConstants.INT101 || cpuFamily === QrConstants.INT108) {
          details = this.libraryServiceData.getFactTagsDetails(GlobalConstants.INTEL_CORE_K_GAMING, GlobalConstants.PROCESSOR_NAME, GlobalConstants.GAMING, GlobalConstants.DESKTOP_NAME);
          if (cpuFamily === QrConstants.INT099 || cpuFamily === QrConstants.INT108) {
            details.badgeUrl = details.titleArray[0].badgeUrl;
            processorDescriptionTitle = details.titleArray[0].tr_gen_core_title;
          } else if (cpuFamily === QrConstants.INT100) {
            details.badgeUrl = details.titleArray[1].badgeUrl;
            processorDescriptionTitle = details.titleArray[1].tr_gen_core_title;
          } else if (cpuFamily === QrConstants.INT101) {
            details.badgeUrl = details.titleArray[2].badgeUrl;
            processorDescriptionTitle = details.titleArray[2].tr_gen_core_title;
          }
        } // HX-Gaming IIT2-4645
        else if (cpuFamily === QrConstants.INT112 || cpuFamily === QrConstants.INT113) {
          details = this.libraryServiceData.getFactTagsDetails(GlobalConstants.INTEL_CORE_HX_GAMING, GlobalConstants.PROCESSOR_NAME, GlobalConstants.GAMING, GlobalConstants.LAPTOP_NAME);
          if (cpuFamily === QrConstants.INT112) {
            details.badgeUrl = details?.titleArray[0]?.badgeUrl;
            processorDescriptionTitle = details?.titleArray[0]?.tr_gen_core_title;
          } else if (cpuFamily === QrConstants.INT113) {
            details.badgeUrl = details?.titleArray[1]?.badgeUrl;
            processorDescriptionTitle = details?.titleArray[1]?.tr_gen_core_title;
          }
        }

      }
      else if (this.qrInput.ContentType === GlobalConstants.GAMINGGEN13) {
        if (GlobalConstants.GAMING_13TH_BADGES.includes(processorBadge)) {
          details = this.libraryServiceData.getFactTagsDetails(GlobalConstants.INTEL_CORE_13TH_GEN_GAMING, GlobalConstants.PROCESSOR_NAME, GlobalConstants.GAMING, GlobalConstants.DESKTOP_NAME);
          if (processorBadge === GlobalConstants.INT126 || processorBadge === GlobalConstants.INT127) {
            details.badgeUrl = details?.titleArray[0]?.badgeUrl;
            processorDescriptionTitle = details?.titleArray[0]?.tr_gen_core_title;
          } else if (processorBadge === GlobalConstants.INT128 || processorBadge === GlobalConstants.INT129) {
            details.badgeUrl = details?.titleArray[1]?.badgeUrl;
            processorDescriptionTitle = details?.titleArray[1]?.tr_gen_core_title;
          } else if (processorBadge === GlobalConstants.INT130 || processorBadge === GlobalConstants.INT131) {
            details.badgeUrl = details?.titleArray[2]?.badgeUrl;
            processorDescriptionTitle = details?.titleArray[2]?.tr_gen_core_title;
          }
        }

      }
      else if (this.qrInput.ContentType === GlobalConstants.GAMINGGEN14) {
        if (GlobalConstants.GAMING_14TH_BADGES.includes(processorBadge)) {
          details = this.libraryServiceData.getFactTagsDetails(GlobalConstants.INTEL_CORE_ABOVE13TH_GEN_GAMING, GlobalConstants.PROCESSOR_NAME, GlobalConstants.GAMING, GlobalConstants.DESKTOP_NAME);
          if (processorBadge === GlobalConstants.INT139 || processorBadge === GlobalConstants.INT142) {
            details.badgeUrl = details?.titleArray[0]?.badgeUrl;
            processorDescriptionTitle = details?.titleArray[0]?.tr_gen_core_title;
          } else if (processorBadge === GlobalConstants.INT140 || processorBadge === GlobalConstants.INT143) {
            details.badgeUrl = details?.titleArray[1]?.badgeUrl;
            processorDescriptionTitle = details?.titleArray[1]?.tr_gen_core_title;
          } else if (processorBadge === GlobalConstants.INT141 || processorBadge === GlobalConstants.INT144) {
            details.badgeUrl = details?.titleArray[2]?.badgeUrl;
            processorDescriptionTitle = details?.titleArray[2]?.tr_gen_core_title;
          }
        } else if (GlobalConstants.GAMING_1ST_GEN_ULTRA_BADGES.includes(processorBadge)) {
          details = this.libraryServiceData.getFactTagsDetails(GlobalConstants.INTEL_CORE_1ST_GEN_ULTRA_GAMING, GlobalConstants.PROCESSOR_NAME, GlobalConstants.GAMING, GlobalConstants.DESKTOP_NAME);
          if (processorBadge === GlobalConstants.INT152) {
            details.badgeUrl = details?.titleArray[0]?.badgeUrl;
            processorDescriptionTitle = details?.titleArray[0]?.tr_gen_core_title;
          } else if (processorBadge === GlobalConstants.INT153) {
            details.badgeUrl = details?.titleArray[1]?.badgeUrl;
            processorDescriptionTitle = details?.titleArray[1]?.tr_gen_core_title;
          } else if (processorBadge === GlobalConstants.INT154) {
            details.badgeUrl = details?.titleArray[2]?.badgeUrl;
            processorDescriptionTitle = details?.titleArray[2]?.tr_gen_core_title;
          }
        }
      }
       else {
        if (cpuFamily === QrConstants.INT064 || cpuFamily === QrConstants.INT065 ||
          cpuFamily === QrConstants.INT006 || cpuFamily === QrConstants.INT005 ||
          cpuFamily === QrConstants.INT063 || cpuFamily === QrConstants.INT062) { // IJR-2802
          details = this.libraryServiceData.getFactTagsDetails(GlobalConstants.OTHER_OLD_PROCESSOR, GlobalConstants.PROCESSOR_NAME, GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);

          processorDescriptionTitle = this.legacyProcessorDescription(details);
        }
        else if(GlobalConstants.INTEL_PROCESSOR_BADGES.includes(this.qrInput?.ProcessorBadge)){
          details = this.libraryServiceData.getFactTagsDetails(GlobalConstants.INTEL_INSIDE, GlobalConstants.PROCESSOR_NAME, GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE); 
          details.badgeUrl = this.qrInput?.ProcessorBadge === GlobalConstants.INT137 ? details.badgeUrl : GlobalConstants.INTEL_INSIDE_I3_BADGE;
          processorDescriptionTitle = processorBadge === GlobalConstants.INT137 ? details?.tr_title : this.qrInput?.ProcessorDescription;                  
        } else if (cpuFamily === QrConstants.INT066 || cpuFamily === QrConstants.INT067 ||
          cpuFamily === QrConstants.INT068 || cpuFamily === QrConstants.INT111) {
          let passCpuBadge;
          if (cpuFamily === QrConstants.INT066) {
            passCpuBadge = GlobalConstants.PENTIUM_SILVER;
          } else if (cpuFamily === QrConstants.INT067) {
            passCpuBadge = GlobalConstants.PENTIUM_GOLD;
          } else if (cpuFamily === QrConstants.INT068 || cpuFamily === QrConstants.INT111) {
            passCpuBadge = GlobalConstants.CPU_FAMILY_CELERON;
          }
          details = this.libraryServiceData.getFactTagsDetails(passCpuBadge, GlobalConstants.PROCESSOR_NAME, GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
          if ( cpuFamily === QrConstants.INT111) {
            details.badgeUrl = GlobalConstants.INTEL_CELERON_1_BADGE;
          }
          
        } // 12 th gen standard start
        else if (cpuFamily === QrConstants.INT102 ||
          cpuFamily === QrConstants.INT103 || cpuFamily === QrConstants.INT104) {
          details = this.libraryServiceData.getFactTagsDetails(cpuFamily, GlobalConstants.PROCESSOR_NAME, GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
        } else if (cpuFamily === QrConstants.INT105) {
          details = this.libraryServiceData.getFactTagsDetails(GlobalConstants.INTEL_CORE_K_GAMING, GlobalConstants.PROCESSOR_NAME, GlobalConstants.GAMING, GlobalConstants.DESKTOP_NAME);
          details.badgeUrl = details?.titleArray[2]?.badgeUrl;
          processorDescriptionTitle = details?.titleArray[2]?.tr_gen_core_title;
          // 12 th gen standard end
        } else if (cpuFamily === QrConstants.INT114) { // HX std i9
          details = this.libraryServiceData.getFactTagsDetails(GlobalConstants.INTEL_CORE_HX_GAMING, GlobalConstants.PROCESSOR_NAME, GlobalConstants.GAMING, GlobalConstants.LAPTOP_NAME);
          details.badgeUrl = details?.titleArray[1]?.badgeUrl;
          processorDescriptionTitle = details?.titleArray[1]?.tr_gen_core_title;
        } else if (cpuFamily === QrConstants.INT115 || cpuFamily === QrConstants.INT116 || cpuFamily === QrConstants.INT117 ||
          cpuFamily === QrConstants.INT163 || cpuFamily === QrConstants.INT164 || cpuFamily === QrConstants.INT165) { // 12th gen Vpro
          details = this.libraryServiceData.getFactTagsDetails(cpuFamily, GlobalConstants.PROCESSOR_NAME, GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
          details.badgeUrl = details?.badgeUrl;
          processorDescriptionTitle = details?.tr_gen_core_title;
        } else if (cpuFamily === QrConstants.INT119 || cpuFamily === QrConstants.INT120 || cpuFamily === QrConstants.INT121) {
          details = this.libraryServiceData.getFactTagsDetails(cpuFamily, GlobalConstants.PROCESSOR_NAME, GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
        }  else if (cpuFamily === QrConstants.INT125) {
          details = this.libraryServiceData.getFactTagsDetails(GlobalConstants.INTEL_CORE_13TH_GEN_GAMING, GlobalConstants.PROCESSOR_NAME, GlobalConstants.GAMING, GlobalConstants.DESKTOP_NAME);
          details.badgeUrl = details?.titleArray[2]?.badgeUrl;
          processorDescriptionTitle = details?.titleArray[2]?.tr_gen_core_title;
        } 
        else if (cpuFamily === QrConstants.INT148) {
          details = this.libraryServiceData.getFactTagsDetails(GlobalConstants.INTEL_CORE_14TH_GEN_GAMING, GlobalConstants.PROCESSOR_NAME, GlobalConstants.GAMING, GlobalConstants.DESKTOP_NAME);
          details.badgeUrl = details?.titleArray[2]?.badgeUrl;
          processorDescriptionTitle = details?.titleArray[2]?.tr_gen_core_title;
        } 
        else if (cpuFamily === QrConstants.INT145 || cpuFamily === QrConstants.INT146 || cpuFamily === QrConstants.INT147 ||
          cpuFamily === QrConstants.INT149 || cpuFamily === QrConstants.INT150 || cpuFamily === QrConstants.INT151) {
          details = this.libraryServiceData.getFactTagsDetails(cpuFamily, GlobalConstants.PROCESSOR_NAME, GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
        } 
        else if (cpuFamily === QrConstants.INT157 || cpuFamily === QrConstants.INT158 ||cpuFamily === QrConstants.INT159 || cpuFamily === QrConstants.INT160 || cpuFamily === QrConstants.INT161 || cpuFamily === QrConstants.INT162) {
          details = this.libraryServiceData.getFactTagsDetails(GlobalConstants.INTEL_CORE_1ST_GEN_ULTRA_GAMING, GlobalConstants.PROCESSOR_NAME, GlobalConstants.GAMING, GlobalConstants.DESKTOP_NAME);
          if (processorBadge === GlobalConstants.INT157 || processorBadge === GlobalConstants.INT160) {
            details.badgeUrl = details?.titleArray[0]?.badgeUrl;
            processorDescriptionTitle = details?.titleArray[0]?.tr_gen_core_title;
          } else if (processorBadge === GlobalConstants.INT158 || processorBadge === GlobalConstants.INT161) {
            details.badgeUrl = details?.titleArray[1]?.badgeUrl;
            processorDescriptionTitle = details?.titleArray[1]?.tr_gen_core_title;
          } else if (processorBadge === GlobalConstants.INT159 || processorBadge === GlobalConstants.INT162) {
            details.badgeUrl = details?.titleArray[2]?.badgeUrl;
            processorDescriptionTitle = details?.titleArray[2]?.tr_gen_core_title;
          }
        }
        else if(GlobalConstants.LUNAR_BADGES.includes(processorBadge)){          
          details = this.libraryServiceData.getFactTagsDetails(QrConstants[processorBadge], GlobalConstants.PROCESSOR_NAME, GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
        }
         else {
          let newDeviceType = GlobalConstants.LAPTOP_NAME;
          // Added badges for Story IJR-19, 66 processor changes
          let cpuBadgeName = cpuFamily;
          if (cpuFamily === QrConstants.INT017 || cpuFamily === QrConstants.INT028 || cpuFamily === QrConstants.INT041 ||
            cpuFamily === QrConstants.INT016 || cpuFamily === QrConstants.INT027 || cpuFamily === QrConstants.INT040 ||
            cpuFamily === QrConstants.INT080 || cpuFamily === QrConstants.INT081 || cpuFamily === QrConstants.INT082 ||
            cpuFamily === QrConstants.INT083 ||
            cpuFamily === QrConstants.INT086 || cpuFamily === QrConstants.INT088 ||
            cpuFamily === QrConstants.INT079) {  // IJR-1332
            newDeviceType = GlobalConstants.DEFAULT_TYPE;
          } else if (cpuFamily === QrConstants.INT084 || cpuFamily === QrConstants.INT085 || cpuFamily === QrConstants.INT087) { // // IJR-5 Hybrid CPU start , UAT-337
            newDeviceType = this.qrInput.DeviceType === GlobalConstants.DEVICE_TYPE_DESKTOP ?
              GlobalConstants.DESKTOP_NAME : GlobalConstants.LAPTOP_NAME;
          } // Hybrid processor
          if ( cpuFamily === QrConstants.INT073 ) { //IIT2-2256
            newDeviceType = GlobalConstants.DEFAULT_TYPE;
            details = this.libraryServiceData.getFactTagsDetails(cpuBadgeName, GlobalConstants.PROCESSOR_NAME, GlobalConstants.GAMING, newDeviceType);
          }else{
            details = this.libraryServiceData.getFactTagsDetails(cpuBadgeName, GlobalConstants.PROCESSOR_NAME, GlobalConstants.STANDARD, newDeviceType);
          }
          if (details.tr_gen_core_title && Number(this.qrInput.ClockSpeed) !== -1) {
            processorDescriptionTitle = details.tr_gen_core_title;
          }  // processor title description
          // IJR-300 Hybrid Badge issue
          const hybridBadgeData = this.libraryServiceData.getHybridDetails();
          if (cpuFamily === QrConstants.INT084) {
            details.badgeUrl = hybridBadgeData[0].badgeURL;
            if (Number(this.qrInput.ClockSpeed) !== -1) {
              processorDescriptionTitle = hybridBadgeData[0].tr_gen_core_title;
            }
          } else if (cpuFamily === QrConstants.INT085) {
            details.badgeUrl = hybridBadgeData[1].badgeURL;
            if (Number(this.qrInput.ClockSpeed) !== -1) {
              processorDescriptionTitle = hybridBadgeData[1].tr_gen_core_title;
            }
          }
          // IJR-300 Hybrid Badge issue
        }
      }
    }

    if ((cpuFamily !== QrConstants.INT084 || cpuFamily !== QrConstants.INT085) && details.tr_gen_core_title
      && Number(this.qrInput.ClockSpeed) !== -1) {
      processorDescriptionTitle = details.tr_gen_core_title;
    }  // processor title description
    const evoVprobadge = GlobalConstants.EVO_VPRO_BADGES[processorBadge];
    if(evoVprobadge && this.qrInput?.VproEvo === GlobalConstants.CHECK_Y) {
      details.badgeUrl = evoVprobadge;
    }
    const title = this.libraryServiceData.getStaticText('tr_processorTitle');
    const homeTitle = this.libraryServiceData.getStaticText('tr_processorTitleCamel');
    // processor title description
    facttag = this.setFactTagsData(details, GlobalConstants.PROCESSOR_NAME, processorDescriptionTitle, title, homeTitle);
    return facttag;
  }

  setGraphicsFactTagData(): FactTag {
    let graphicsBadge = (this.qrInput?.GraphicsBadge === QrConstants.GRAPHICS_UNKNOWN) ? QrConstants.INTEL_HD_GRAPHICS
    : this.qrInput?.GraphicsBadge;
      let details;
      if( QrConstants.ARC_GRAPHICS.indexOf(this.qrInput?.GraphicsBadge)!==-1 ||(QrConstants.ARC_GRAPHICS.indexOf(this.qrInput?.GraphicsBadge)===-1 && this.qrInput?.GraphicsBadge[0] === 'A') || this.qrInput?.GraphicsBadge === QrConstants.BADGE_GIN012) {
        graphicsBadge='ARC001';
        details = this.libraryServiceData.getFactTagsDetails(QrConstants[graphicsBadge], GlobalConstants.GRAPHICS_NAME, GlobalConstants.DEFAULT_TYPE, GlobalConstants.DEFAULT_TYPE);
      } else {
        details = this.libraryServiceData.getFactTagsDetails(QrConstants[graphicsBadge], GlobalConstants.GRAPHICS_NAME, GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
      }
        let homeTitle = this.libraryServiceData.getStaticText('tr_graphicTitleCamel');
        let title = this.libraryServiceData.getStaticText('tr_graphicTitle');
        return this.setFactTagsData(details, GlobalConstants.GRAPHICS_NAME, this.qrInput?.GraphicsDescription, title, homeTitle);            
  }

  setSecondaryGraphicsFactTagData(): FactTag {
    const graphicsBadge = (this.qrInput.SecondaryGraphicsBadge === QrConstants.GRAPHICS_UNKNOWN) ? QrConstants.INTEL_HD_GRAPHICS
      : this.qrInput.SecondaryGraphicsBadge;
    let details;
    if( QrConstants.ARC_GRAPHICS.indexOf(this.qrInput?.SecondaryGraphicsBadge)!==-1 ||(QrConstants.ARC_GRAPHICS.indexOf(this.qrInput?.SecondaryGraphicsBadge)===-1 && this.qrInput?.SecondaryGraphicsBadge[0] === 'A')) {
      details = this.libraryServiceData.getFactTagsDetails(QrConstants['ARC001'], GlobalConstants.GRAPHICS_NAME, GlobalConstants.DEFAULT_TYPE, GlobalConstants.DEFAULT_TYPE);
    } else {
      details = this.libraryServiceData.getFactTagsDetails(QrConstants[graphicsBadge], GlobalConstants.GRAPHICS_NAME, 'STANDARD', 'DEFAULT');
    }
    const homeTitle = this.libraryServiceData.getStaticText('tr_graphicTitleCamel')
    let title = this.libraryServiceData.getStaticText('tr_graphicTitle')
    return this.setSecondaryFactTagsData(details, GlobalConstants.GRAPHICS_CARD, this.qrInput.SecondaryGraphicsCard, title, homeTitle);
  }

  private setFactTagsData(details, tagName, descVal, title?: string, homeTitle?: string): FactTag {
      const factTag = {
      name: tagName,
      title: title,
      description: descVal,
      image: ((this.qrInput.GraphicsBadge === QrConstants.GRAPHICS_UNKNOWN ||
        (this.qrInput.ContentType !== GlobalConstants.STANDARDGEN11 && this.qrInput.ContentType !== GlobalConstants.CREATORGEN13&&
          this.qrInput.ContentType !== GlobalConstants.GAMINGGEN11 && this.qrInput.ContentType !== GlobalConstants.GAMINGGEN12 &&
          this.qrInput.ContentType !== GlobalConstants.STANDARDGEN13 && this.qrInput.ContentType !== GlobalConstants.GAMINGGEN13 &&
          this.qrInput.ContentType !== GlobalConstants.STANDARDGEN12 &&  this.qrInput.ContentType !== GlobalConstants.STANDARDGEN14 &&
          this.qrInput.ContentType !== GlobalConstants.EVO && this.qrInput.ContentType !== GlobalConstants.GAMINGGEN14 &&
          QrConstants.ARC_GRAPHICS.indexOf(this.qrInput?.GraphicsBadge)==-1)) && tagName === GlobalConstants.GRAPHICS_NAME) ? '' // IJR-2405
        : details.badgeUrl,
      homeTitle: homeTitle

    };
    return factTag;
  }

  public setRemoteFactTag(data): void {
    this.remotefactTags = [];
    this.remotefactTags = data;
  }

  setComparePageFactTags(qrCode: FactTag[]) {
    this.comparePageFactTags.next(qrCode);
  }

  getComparePageFactTags(): Observable<FactTag[]> {
    return this.comparePageFactTags.asObservable();
  }

  public getQrUrlSource(): string[] {
    return this.qrURLSource;
  }

  public setQrUrlSource(data: string[]): void {
    this.qrURLSource = data;
  }

  public getInitialScannedUrl(): string {
    return this.initialScannedUrl;
  }

  setenusLang() {

    let languageEnglish = this.qrInput.Language;
    this.languageFamily = languageEnglish === GlobalConstants.EN_US_SMALL;
    return this.languageFamily;
  }


  transformDecimal(number): string {
    this.language = this.qrInput.Language;

    switch (this.language) {
      case 'ar-sa':
        this.transformedDecimal = new Intl.NumberFormat('en-EN').format(number);
        break;
      case 'cs':
        this.transformedDecimal = new Intl.NumberFormat('cs-CS').format(number);
        break;
      case 'da':
        this.transformedDecimal = new Intl.NumberFormat('da-DA').format(number);
        break;
      case 'de':
        this.transformedDecimal = new Intl.NumberFormat('de-DE').format(number);
        break;
      case 'el':
        this.transformedDecimal = new Intl.NumberFormat('el-EL').format(number);
        break;
      case 'es':
      case 'es-mx':
        this.transformedDecimal = new Intl.NumberFormat('es-ES').format(number);
        this.transformedDecimal = this.transformedDecimal.split('.').join(' ');
        break;
      case 'fi':
        this.transformedDecimal = new Intl.NumberFormat('fi-FI').format(number);
        break;
      case 'fr':
        this.transformedDecimal = new Intl.NumberFormat('fr-FR').format(number);
        break;
      case 'he':
        this.transformedDecimal = new Intl.NumberFormat('en-EN').format(number);
        break;
      case 'hu':
        this.transformedDecimal = new Intl.NumberFormat('hu-HU').format(number);
        break;
      case 'id':
        this.transformedDecimal = new Intl.NumberFormat('id-ID').format(number);
        break;
      case 'is':
        this.transformedDecimal = new Intl.NumberFormat('de-DE').format(number);
        break;
      case 'it':
        this.transformedDecimal = new Intl.NumberFormat('it-IT').format(number);
        break;
      case 'ja':
        this.transformedDecimal = new Intl.NumberFormat('en-EN').format(number);
        break;
      case 'ko':
        this.transformedDecimal = new Intl.NumberFormat('en-EN').format(number);
        break;
      case 'nl':
        this.transformedDecimal = new Intl.NumberFormat('nl-NL').format(number);
        break;
      case 'nb':
        this.transformedDecimal = new Intl.NumberFormat('nb-NO').format(number);
        break;
      case 'pl':
        this.transformedDecimal = new Intl.NumberFormat('pl-PL').format(number);
        break;
      case 'pt':
        this.transformedDecimal = new Intl.NumberFormat('pt-PT').format(number);
        break;
      case 'pt-br':
        this.transformedDecimal = new Intl.NumberFormat('pt-BR').format(number);
        break;
      case 'ro':
        this.transformedDecimal = new Intl.NumberFormat('ro-RO').format(number);
        break;
      case 'ru':
        this.transformedDecimal = new Intl.NumberFormat('ru-RU').format(number);
        break;
      case 'sk':
        this.transformedDecimal = new Intl.NumberFormat('sk-SK').format(number);
        break;
      case 'sv':
        this.transformedDecimal = new Intl.NumberFormat('sv-SV').format(number);
        break;
      case 'th':
        this.transformedDecimal = new Intl.NumberFormat('en-EN').format(number);
        break;
      case 'tr':
        this.transformedDecimal = new Intl.NumberFormat('tr-TR').format(number);
        break;
      case 'uk':
        this.transformedDecimal = new Intl.NumberFormat('uk-UK').format(number);
        break;
      case 'vi':
        this.transformedDecimal = new Intl.NumberFormat('vi-VI').format(number);
        break;
      case 'zh-cn':
        this.transformedDecimal = new Intl.NumberFormat('en-EN').format(number);
        break;
      case 'zh-tw':
        this.transformedDecimal = new Intl.NumberFormat('en-EN').format(number);
        break;
      default:
        this.transformedDecimal = new Intl.NumberFormat('en-EN').format(number);
        break;
    }
    return this.transformedDecimal;
  }

  // IJR-98 MULTIPLE GRAPHICS CARD START
  changeGraphicsCardDetails(graphicsValues: boolean) {
    this.graphicsCardDetails.next(graphicsValues)
  }

  private setSecondaryFactTagsData(details, tagName, descVal, title?: string, homeTitle?: string): FactTag {
    if (!this.qrInput.GraphicsBadge) {
      tagName = GlobalConstants.GRAPHICS_NAME;
      homeTitle = this.libraryServiceData.getStaticText('tr_graphicTitleCamel');
      title = this.libraryServiceData.getStaticText('tr_graphicTitle');
    }
    const factTag = {
      name: tagName,
      title: title,
      description: descVal,
      image: ((this.qrInput.SecondaryGraphicsBadge === QrConstants.GRAPHICS_UNKNOWN || (this.qrInput.ContentType !== GlobalConstants.STANDARDGEN11 && this.qrInput.ContentType !== GlobalConstants.STANDARDGEN12 && this.qrInput.ContentType !== GlobalConstants.GAMINGGEN11 && this.qrInput.ContentType !== GlobalConstants.EVO)) && tagName === GlobalConstants.GRAPHICS_NAME) ? ''
        : details.badgeUrl,
      homeTitle: homeTitle

    };
    return factTag;
  }
  // IJR-98 MULTIPLE GRAPHICS CARD END
  private setChromebookProcessorFactTagData(): FactTag {
    let facttag;
    let details;
    let processorBadge = this.qrInput.ProcessorBadge;
    const cpuFamily = QrConstants[processorBadge];
    const title = this.librarychromebook.getStaticText('tr_processorTitle');
    const homeTitle = this.librarychromebook.getStaticText('tr_processorTitleCamel');
    let processorTitle = this.qrInput.ProcessorDescription;
    const evoBadge={
      'INT142':"assets/images/processor/EVO_generic.svg",
      'INT143':"assets/images/processor/EVO_I5_12GEN.svg",
      'INT144':"assets/images/processor/EVO_I7_GEN12.svg",
      'INT145': "assets/images/processor/EVO_generic.svg",
      'INT146': "assets/images/processor/EVO_I5_12GEN.svg",
      'INT147': "assets/images/processor/EVO_I7_GEN12.svg",
      'INT148': "assets/images/processor/EVO_I9_GEN12.svg"     
    }
    if (Utils.isChromebookProcessor(processorBadge, this.qrInput.ContentType)) {
      if (this.qrInput.ContentType === GlobalConstants.CHROMEBOOKATHENA) {
        details = this.librarychromebook.getProcessorFactTagsDetails
          (cpuFamily, GlobalConstants.PROCESSOR_NAME, GlobalConstants.STANDARD, GlobalConstants.ATHENA_CONTENT_TYPE);
        if (details.tr_gen_core_title && Number(this.qrInput.ClockSpeed) !== -1) {
          processorTitle = details.tr_gen_core_title;
        }
        facttag = this.setFactTagsData(details, GlobalConstants.PROCESSOR_NAME, processorTitle, title, homeTitle);
      } else if (this.qrInput.ContentType === GlobalConstants.CHROMEBOOK_CELERON_AND_PENTIUM) { // Check if badge is of pentium or celeron
        processorBadge = this.chromePentiumCeleronOldBadge(); // IIT2-2504
        details = this.librarychromebook.getProcessorFactTagsDetails
          (QrConstants[processorBadge], GlobalConstants.PROCESSOR_NAME, GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
          
          details.badgeUrl = details.badgeUrl.replace(GlobalConstants.VALUE_5, QrConstants[processorBadge]);
        
          if (processorBadge === 'INT005') {
            details.badgeUrl = GlobalConstants.INTEL_CELERON_1_BADGE;
          } else if (processorBadge === 'INT062') {
            details.badgeUrl = GlobalConstants.INTEL_PENTIUM_1_BADGE;
          } else if (processorBadge === 'INT111') {
            details.badgeUrl = GlobalConstants.INTEL_CELERON_1_BADGE;
          }

        if (details.tr_gen_core_title && Number(this.qrInput.ClockSpeed) !== -1) {
          processorTitle = details.tr_gen_core_title;
        }
        facttag = this.setFactTagsData(details, GlobalConstants.PROCESSOR_NAME, processorTitle, title, homeTitle);
      } else if (this.qrInput.ContentType === GlobalConstants.CHROMEBOOKEVO) {  // IIT2-2221 start
        details = this.librarychromebook.getProcessorFactTagsDetails
          (cpuFamily, GlobalConstants.PROCESSOR_NAME, GlobalConstants.EVO_CAPITAL, GlobalConstants.DEFAULT_TYPE);
        if (details.tr_gen_core_title && Number(this.qrInput.ClockSpeed) !== -1) {
          processorTitle = details.tr_gen_core_title;
        }
        if(GlobalConstants.CHROME_RPL_BADGES.includes(this.qrInput?.ProcessorBadge)){
          details.badgeUrl= evoBadge[processorBadge];
        }
        facttag = this.setFactTagsData(details, GlobalConstants.PROCESSOR_NAME, processorTitle, title, homeTitle); // IIT2-2221 end
      } else if(GlobalConstants.INTEL_PROCESSOR_BADGES.includes(this.qrInput?.ProcessorBadge)){
        details =  details = this.librarychromebook.getProcessorFactTagsDetails
        (GlobalConstants.INTEL_INSIDE_13, GlobalConstants.PROCESSOR_NAME, GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
        details.badgeUrl = this.qrInput?.ProcessorBadge === GlobalConstants.INT137 ? details.badgeUrl : GlobalConstants.INTEL_INSIDE_I3_BADGE;
        processorTitle = processorBadge === GlobalConstants.INT137 ? details?.tr_title : this.qrInput?.ProcessorDescription;                  
        facttag = this.setFactTagsData(details, GlobalConstants.PROCESSOR_NAME, processorTitle, title, homeTitle);
      } else {
        details = this.librarychromebook.getProcessorFactTagsDetails
          (cpuFamily, GlobalConstants.PROCESSOR_NAME, GlobalConstants.STANDARD, GlobalConstants.DEFAULT_TYPE);
        if (details.tr_gen_core_title && Number(this.qrInput.ClockSpeed) !== -1) {
          processorTitle = details.tr_gen_core_title;
        }
        facttag = this.setFactTagsData(details, GlobalConstants.PROCESSOR_NAME, processorTitle, title, homeTitle);
      }
    } else {
      facttag = {
        name: GlobalConstants.PROCESSOR_NAME,
        title: title,
        description: processorTitle,
        image: GlobalConstants.PROCESSOR_BADGE.replace(GlobalConstants.XX, QrConstants[processorBadge]),
        homeTitle: homeTitle
      };
    }

    return facttag;
  }

  private setCreatorProcessorFactTagData(): FactTag {
    let facttag;
    let details;
    let processorTitle;
    const processorBadge = this.qrInput.ProcessorBadge;
    const cpuFamily = QrConstants[processorBadge];
    let deviceType = (this.qrInput.DeviceType === GlobalConstants.DEVICE_TYPE_DESKTOP) ?
      GlobalConstants.DESKTOP_NAME : GlobalConstants.LAPTOP_NAME;
    const title = this.libraryServiceData.getStaticText('tr_processorTitle');
    const homeTitle = this.libraryServiceData.getStaticText('tr_processorTitleCamel');
    if (cpuFamily === QrConstants.INT042 && deviceType === GlobalConstants.DESKTOP_NAME) {
      details = this.libraryServiceData.getFactTagsDetails(cpuFamily, GlobalConstants.PROCESSOR_NAME, GlobalConstants.STANDARD, deviceType);
      processorTitle = details.tr_gen_core_title;
    } else if (cpuFamily === QrConstants.INT105) {  // IIT2-4761
      details = this.libraryServiceData.getFactTagsDetails(GlobalConstants.INTEL_CORE_K_GAMING, GlobalConstants.PROCESSOR_NAME, GlobalConstants.GAMING, GlobalConstants.DESKTOP_NAME);
      details.badgeUrl = details?.titleArray[2]?.badgeUrl;
      processorTitle = details?.titleArray[2]?.tr_gen_core_title; 
    } else if (cpuFamily === QrConstants.INT114) {  // IIT2-4761
      details = this.libraryServiceData.getFactTagsDetails(GlobalConstants.INTEL_CORE_HX_GAMING, GlobalConstants.PROCESSOR_NAME, GlobalConstants.GAMING, GlobalConstants.LAPTOP_NAME);
      details.badgeUrl = details?.titleArray[1]?.badgeUrl;
      processorTitle = details?.titleArray[1]?.tr_gen_core_title; 
    }
    else if (cpuFamily === QrConstants.INT132 || cpuFamily === QrConstants.INT133) {
      details = this.libraryServiceData.getFactTagsDetails(cpuFamily, GlobalConstants.PROCESSOR_NAME, GlobalConstants.CREATOR, GlobalConstants.LAPTOP_NAME);   
      processorTitle = details?.tr_gen_core_title; 
    }
    else if (cpuFamily === QrConstants.INT135 || cpuFamily === QrConstants.INT134
       || cpuFamily === QrConstants.INT136) {

        details = this.libraryServiceData.getFactTagsDetails(GlobalConstants.INTEL_CORE_I9_CREATOR, GlobalConstants.PROCESSOR_NAME, GlobalConstants.CREATOR, GlobalConstants.LAPTOP_NAME);   
        details.badgeUrl = details?.badgeUrl;
        processorTitle = details?.tr_gen_core_title; 
    } else {
      if (cpuFamily === QrConstants.INT071 || cpuFamily === QrConstants.INT104) {
        deviceType = GlobalConstants.LAPTOP_NAME; // since laptop and desktop contents are same
      }
      details = this.libraryServiceData.getFactTagsDetails(cpuFamily, GlobalConstants.PROCESSOR_NAME, GlobalConstants.CREATOR, deviceType);
      processorTitle = details.tr_gen_core_title;
    }  
    processorTitle = processorTitle && Number(this.qrInput.ClockSpeed) !== -1 ?
      processorTitle : this.qrInput.ProcessorDescription; // processor title description
    facttag = this.setFactTagsData(details, GlobalConstants.PROCESSOR_NAME, processorTitle, title, homeTitle);
    return facttag;
  }

  //For chatbot
  public getLocale() {
    return this.qrInput.Language;
  }

  getDisplayText(factTagType): string {
    if (factTagType === "manufacturer") {
      return Utils.getDeviceIconDetails(this.qrInput.SystemManufacturer);
    }
    else if (factTagType === "graphics") {
      return this.qrInput.GraphicsDescription;
    }
    else if (factTagType === "processor") {
      const processorResult = this.remotefactTags.filter(item=>item.name === "processor");
      return this.qrInput?.ProcessorDescription ? this.qrInput?.ProcessorDescription : processorResult[0]?.description;
    }
    else if (factTagType === "wifi") {
      return this.qrInput?.WifiDisplay; // chatbot wifi display
    }
    else if (factTagType === "memory") {
      return this.setMemoryDescription();
    }
    else if (factTagType === "storage") {
      return this.rethinkStorageDescription();
    }
    else if (factTagType === "OS") {
      return this.qrInput.OSDescription;
    }
    else if (factTagType === "battery") {
      return this.batteryDescriptionChatbot;
    }
    else if (factTagType === "display") {
      return this.displayDescriptionChatbot;
    }
    else if (factTagType === "thunderbolt") {
      return this.connectivityDescriptionChatbot;
    } else if (factTagType === GlobalConstants.TOUCH_SCREEN && this.qrInput.TouchScreen === DisplayConstants.TOUCHSCREEN_YES) {
        return this.touchscreeenText;
    } else if (factTagType === GlobalConstants.VRAM) {
        return this.qrInput.MemoryTotal;
    } else if (factTagType === GlobalConstants.CLOCK_SPEED) {
        return this.qrInput?.CpuClockSpeed;
    } else {
      return null;
    }
  }

  getDasFactTag() {
    let dasDescription: string;
    if (this.qrInput.DasTronc) {
      dasDescription = `${GlobalConstants.DAS_TRONC_VALUE.replace(GlobalConstants.XX, this.qrInput.DasTronc)}`;  // IJR-3064
    }
    if (this.qrInput.DasMembre) {
      dasDescription = dasDescription ? `${dasDescription} , <br> ${GlobalConstants.DAS_MEMBRE_VALUE.replace(GlobalConstants.XX, this.qrInput.DasMembre)}` : `${GlobalConstants.DAS_MEMBRE_VALUE.replace(GlobalConstants.XX, this.qrInput.DasMembre)}`; // IJR-3064
    }
    if (this.qrInput.DasTete) {
      dasDescription = dasDescription ? `${dasDescription} , <br> ${GlobalConstants.DAS_TETE_VALUE.replace(GlobalConstants.XX, this.qrInput.DasTete)}` : `${GlobalConstants.DAS_TETE_VALUE.replace(GlobalConstants.XX, this.qrInput.DasTete)}`; // IJR-3064
    }
    const facttag = {
      name: GlobalConstants.DAS_NAME,
      title: GlobalConstants.DAS_TITLE,
      description: dasDescription,
      homeTitle: GlobalConstants.DAS_TITLE,
      imageURL: this.qrInput.ContentType === GlobalConstants.CREATORGEN11 || this.qrInput.ContentType === GlobalConstants.CREATORGEN12
        ? GlobalConstants.PC_SPECS_DAS_ICON : '' // IIT2 - 2156
    };
    return facttag;
  }
  // Added below code as common functionality for display description
  // Linux OS wont support touchscreen changes
  // bug fix IJR-3072: changed logic
  getDisplayDescription(displayDiscriptionType) {
    if (displayDiscriptionType === GlobalConstants.TILE_SERVICE) {
      this.displayText = this.libraryServiceData.getStaticText('tr_displayText_Display');
      this.displayTextResolution = this.libraryServiceData.getStaticText('tr_displayTextResolution_Display');
      this.touchscreeenText = this.libraryServiceData.getStaticText('tr_displayTextTouchscreeenText');
      this.displayTextResolutionTouchscreen = this.libraryServiceData.getStaticText('tr_displayTextResolutionTouchscreen_Display');
    } else {
      this.displayText = this.libraryServiceData.getStaticText('tr_displayText');
      this.displayTextResolution = this.libraryServiceData.getStaticText('tr_displayTextResolution');
      this.touchscreeenText = this.libraryServiceData.getStaticText('tr_touchscreeenText');
      this.displayTextResolutionTouchscreen = this.libraryServiceData.getStaticText('tr_displayTextResolutionTouchscreen');
    }
    let displayDescription = '';
    const displaySize = this.transformDecimal(this.qrInput.ScreenSize);
    if ((Number(this.qrInput.ScreenSize) > 0 && (this.qrInput.TouchScreen === DisplayConstants.TOUCHSCREEN_YES &&
      this.qrInput.Os !== GlobalConstants.ANA)) && !DisplayConstants.SCREEN_STANDARDS_ALL.includes(this.qrInput.DisplayType)) {
      displayDescription = this.touchscreeenText.replace(DisplayConstants.SCREEN_DIAGONAL, displaySize);
    }
    else if ((Number(this.qrInput.ScreenSize) > 0 && (this.qrInput.TouchScreen === DisplayConstants.TOUCHSCREEN_YES &&
      this.qrInput.Os === GlobalConstants.ANA)) && !DisplayConstants.SCREEN_STANDARDS_ALL.includes(this.qrInput.DisplayType)) {
      displayDescription = this.displayText.replace(DisplayConstants.SCREEN_DIAGONAL, displaySize);
    }
    else if ((Number(this.qrInput.ScreenSize) > 0 && (this.qrInput.TouchScreen === DisplayConstants.TOUCHSCREEN_YES && this.qrInput.Os !== 'ANA') &&
      DisplayConstants.SCREEN_STANDARDS_ALL.includes(this.qrInput.DisplayType))) {
      displayDescription = this.displayTextResolutionTouchscreen.replace(DisplayConstants.SCREEN_DIAGONAL, displaySize)
        .replace(DisplayConstants.SCREEN_STANDARD_PLACEHOLDER, this.qrInput.DisplayType);
    }
    else if ((Number(this.qrInput.ScreenSize) > 0 &&
      ((this.qrInput.TouchScreen === DisplayConstants.TOUCHSCREEN_YES || this.qrInput.TouchScreen === DisplayConstants.TOUCHSCREEN_NO) &&
        (this.qrInput.Os === GlobalConstants.ANA || this.qrInput.Os !== GlobalConstants.ANA)) &&
      DisplayConstants.SCREEN_STANDARDS_ALL.includes(this.qrInput.DisplayType))) {
      displayDescription = this.displayTextResolution.replace(DisplayConstants.SCREEN_DIAGONAL, displaySize)
        .replace(DisplayConstants.SCREEN_STANDARD_PLACEHOLDER, this.qrInput.DisplayType);
    }
    else if ((Number(this.qrInput.ScreenSize) > 0 && (this.qrInput.TouchScreen === DisplayConstants.TOUCHSCREEN_NO))) {
      displayDescription = this.displayText.replace(DisplayConstants.SCREEN_DIAGONAL, displaySize);
    }
    return displayDescription;
  }

  // Common function for storage description
rethinkStorageDescription(): string {
  let ssdValueTB;
  let ssdValueGB;
  let hddValueGB;
  let hddValueTB;
  let description;

  Number(this.qrInput?.StoorageSSDValues) >= 1000 ? ssdValueTB = this.transformDecimal((Number(this.qrInput?.StoorageSSDValues) / 1000).toFixed(1)) :
    ssdValueGB = this.transformDecimal(Number(this.qrInput?.StoorageSSDValues));
  Number(this.qrInput?.StorageHDDValues) >= 1000 ? hddValueTB = this.transformDecimal((Number(this.qrInput?.StorageHDDValues) / 1000).toFixed(1)) :
    hddValueGB = this.transformDecimal(Number(this.qrInput?.StorageHDDValues));
  const libraryType = this.qrInput?.ContentType[0] === GlobalConstants.CHROMEBOOK ? this.librarychromebook : this.libraryServiceData
  if (Number(this.qrInput?.StoorageSSDValues) > 0 && Number(this.qrInput?.StorageHDDValues) > 0) {
    if (ssdValueGB && hddValueGB) {
      description = libraryType.getStaticText('tr_textGBGB').replace(GlobalConstants.CONVERTED_SSD, ssdValueGB)
        .replace(GlobalConstants.CONVERTED_HDD, hddValueGB);
    } else if (ssdValueGB && hddValueTB) {
      description = libraryType.getStaticText('tr_textGBTB').replace(GlobalConstants.CONVERTED_SSD, ssdValueGB)
        .replace(GlobalConstants.CONVERTED_HDD, hddValueTB);
    } else if (ssdValueTB && hddValueGB) {
      description = libraryType.getStaticText('tr_textTBGB').replace(GlobalConstants.CONVERTED_SSD, ssdValueTB)
        .replace(GlobalConstants.CONVERTED_HDD, hddValueGB);
    } else {
      description = libraryType.getStaticText('tr_textTBTB').replace(GlobalConstants.CONVERTED_SSD, ssdValueTB)
        .replace(GlobalConstants.CONVERTED_HDD, hddValueTB);
    }
  } else if (Number(this.qrInput?.StoorageSSDValues) > 0) {
    if (this.qrInput?.ContentType[0] !== GlobalConstants.CHROMEBOOK && this.qrInput?.IsStorageOptaneAvailable === GlobalConstants.CHECK_Y) {
      const libraryText = ssdValueGB > 0 ? 'tr_textStorageWithOptaneGB' : 'tr_textStorageWithOptaneTB';
      const ssdValue = Number(this.qrInput?.StoorageSSDValues) > 1000 ? ssdValueTB : ssdValueGB
      description = libraryType.getStaticText(libraryText).replace(GlobalConstants.CONVERTED_SSD, ssdValue)
                               .replace(GlobalConstants.XX, this.transformDecimal(this.qrInput?.StorageOptaneValues))
    } else {
    description = ssdValueGB ? libraryType.getStaticText('tr_textGBSSD').replace(GlobalConstants.CONVERTED_SSD, ssdValueGB) :
      libraryType.getStaticText('tr_textTBSSD').replace(GlobalConstants.CONVERTED_SSD, ssdValueTB);
    }
  } else if (Number(this.qrInput?.StorageHDDValues) > 0) {
    description = hddValueGB ? libraryType.getStaticText('tr_textGBHDD').replace(GlobalConstants.CONVERTED_HDD, hddValueGB) :
      libraryType.getStaticText('tr_textTBHDD').replace(GlobalConstants.CONVERTED_HDD, hddValueTB);
  }

  return description;
}
  // IIT2-2885
  public standardEnhancement() {
    return this.qrInput?.ContentType === GlobalConstants.STANDARDGEN12 ||
      this.qrInput?.ContentType === GlobalConstants.STANDARDGEN11 ||
      this.qrInput?.ContentType === GlobalConstants.CREATORGEN11 ||
      this.qrInput?.ContentType === GlobalConstants.CREATORGEN12 || 
      this.qrInput?.ContentType === GlobalConstants.CREATORGEN13 ||    //IIT2-4761
      this.qrInput?.ContentType === GlobalConstants.EVO || // IJR-4070
      this.qrInput?.ContentType === GlobalConstants.STANDARDGEN13 || this.qrInput?.ContentType === GlobalConstants.STANDARDGEN14; // 13th gen
  }

  //IIT2-3585 starts
  changeVideoPlayingStatus(value: boolean) {
    this.videoPlaying.next(value);
  }
  //IIT2-3585 ends
  // IIT2-4515
  chromePentiumCeleronOldBadge() {
    let badge = this.qrInput.ProcessorBadge;
    if (this.qrInput.ProcessorBadge === 'INT005') {
      badge = 'INT006';
    } else if (this.qrInput.ProcessorBadge === 'INT062') {
      badge = 'INT063';
    } else if (this.qrInput.ProcessorBadge === 'INT111') {
      badge = 'INT068';
    }
    return badge;
  }

  legacyProcessorDescription(details) {
    let processorDescriptionTitle;
    if (QrConstants[this.qrInput.ProcessorBadge] === QrConstants.INT064) {
      details.badgeUrl = details.badgeIcons[1].badgeUrl;
      if (Number(this.qrInput.ClockSpeed) !== -1) {
        processorDescriptionTitle = details.titleArray[1].tr_gen_core_title;  // IJR-3052
      }
    } else if (QrConstants[this.qrInput.ProcessorBadge] === QrConstants.INT065) {
      details.badgeUrl = details.badgeIcons[0].badgeUrl;
      if (Number(this.qrInput.ClockSpeed) !== -1) {
        processorDescriptionTitle = details.titleArray[0].tr_gen_core_title;  // IJR-3052
      }
    } else if (QrConstants[this.qrInput.ProcessorBadge] === QrConstants.INT006 || QrConstants[this.qrInput.ProcessorBadge] === QrConstants.INT005) {
      details.badgeUrl = QrConstants[this.qrInput.ProcessorBadge] === QrConstants.INT006 ?
        details.badgeIcons[2].badgeUrl : GlobalConstants.INTEL_CELERON_1_BADGE;

      if (Number(this.qrInput.ClockSpeed) !== -1) {
        processorDescriptionTitle = details.titleArray[2].tr_gen_core_title;  // IJR-3052
      }
    } else if (QrConstants[this.qrInput.ProcessorBadge] === QrConstants.INT063 || QrConstants[this.qrInput.ProcessorBadge] === QrConstants.INT062) { // IJR-2802
      details.badgeUrl = QrConstants[this.qrInput.ProcessorBadge] === QrConstants.INT063 ?
        details.badgeIcons[3].badgeUrl : GlobalConstants.INTEL_PENTIUM_1_BADGE;
      // details.badgeUrl = details.badgeIcons[3].badgeUrl;
      if (Number(this.qrInput.ClockSpeed) !== -1) {
        processorDescriptionTitle = details.titleArray[3].tr_gen_core_title;  // IJR-3052
      }
    }
    return processorDescriptionTitle;
  }

  public gamaingEnhancement() {
    return this.qrInput?.ContentType === GlobalConstants.GAMINGGEN11 ||
           this.qrInput?.ContentType === GlobalConstants.GAMINGGEN12 ||
           this.qrInput?.ContentType === GlobalConstants.GAMINGGEN13 ||
           this.qrInput?.ContentType === GlobalConstants.GAMINGGEN14
  }

  public isAppGaming() {
    return this.qrInput?.ContentType === GlobalConstants.GAMINGGEN9 ||
           this.qrInput?.ContentType === GlobalConstants.GAMINGGEN10 ||
           this.qrInput?.ContentType === GlobalConstants.GAMINGGEN11 ||
           this.qrInput?.ContentType === GlobalConstants.GAMINGGEN12 ||
           this.qrInput?.ContentType === GlobalConstants.GAMINGGEN13 ||
           this.qrInput?.ContentType === GlobalConstants.GAMINGGEN14
  }

  public isAiIpos(){
    return GlobalConstants.EVO_MTL_BADGES?.includes(this.qrInput.ProcessorBadge) || GlobalConstants.STD_MTL_BADGES?.includes(this.qrInput.ProcessorBadge) || GlobalConstants.GAMING_1ST_GEN_ULTRA_BADGES.includes(this.qrInput.ProcessorBadge) || GlobalConstants.LUNAR_BADGES?.includes(this.qrInput.ProcessorBadge);
  }

  setMemoryDescription() {
    if (this.qrInput?.Optane_Msg === GlobalConstants.CHECK_Y && Number(this.qrInput?.OptaneMemorySize) > 0) {
      const val1 = Number(this.qrInput?.OptaneMemorySize) + Number(this.qrInput?.ActualMemory);
      return this.libraryServiceData.getStaticText('tr_memoryWithOptaneDesc')
      .replace('[Value1]',  this.transformDecimal(val1))
      .replace('[Value2]',  this.transformDecimal(this.qrInput?.OptaneMemorySize))
      .replace('[Value3]',  this.transformDecimal(this.qrInput?.ActualMemory));
    } else {
      return this.libraryServiceData.getStaticText('tr_memoryValue').replace(GlobalConstants.XX, this.transformDecimal(this.qrInput?.TotalMemory));
    }
  }
 
  setContentType(values) {
    return values[4] === 'SNA' && GlobalConstants.INTEL_PROCESSOR_BADGES.includes(values[5]) ? 'S12' : values[4]
  }

  setCpuManufacturer(manufacturerValue) {
    return manufacturerValue === QrConstants.I_TEXT || manufacturerValue === GlobalConstants.INTEL_CAPS ? GlobalConstants.INTEL_CAPS : GlobalConstants.NON_INTEL_CONTENT;
  }

  setWifiFamilyValues(wifiFamily) {    
    return wifiFamily?.length > 2 ? wifiFamily : wifiFamilyValues[wifiFamily];
  }

  setOSDescription(osValue) {
    const osMappingValue = 'tr_OS' + osValue;
    return osValue?.length > 2 ? osValue : this.libraryServiceData.getStaticText('tr_OSDescriptionMapping')?.[osMappingValue];
  }
  setWifiDescription(wifiDesc){
    const wifiDescMapping = 'tr_Wifi' + wifiDesc;
    return wifiDesc?.length > 2 ? wifiDesc : this.libraryServiceData.getStaticText('tr_WifiDescMapping')?.[wifiDescMapping];
  }
  
  setGraphicsDesc(graphicsDesc){
    const graphicsMapping = 'tr_GR' + graphicsDesc;
    return graphicsDesc?.length > 3 ? graphicsDesc : this.libraryServiceData.getStaticText('tr_GraphicsDescriptionMapping')?.[graphicsMapping];
  }
}
