<div id="mobileContentContainer"  class="container1" *ngIf="!openModalPopup">
  <div id="chromebookHomeBody" class="home-body" [class.ai-ipos-body]="isAiIpos" [class.chromebook-body]="isChromeBook"> <!--II-3393-->
    <ng-container *ngIf="!isGen12Or11">
      <div id="intelMobileContent" class="intel_section" *ngIf="!isNonIntel">
        <div id="factTagMenu" *ngFor="let item of factTagMenu; let i = index">
          <div id="procTitle" class="proc-tile" [class.creator-card]="isCreator" *ngIf="((item.title === processorValue && !isNonIntel))" (click)="(disableProcessorsFacttag) ? false : gotoDetailPage(item.name, i)">
            <div id="chromebookBadge" class="badge-wrapper" [class.chromebook-processor-badge]="isChromeBook">
              <img src="{{item.image}}" alt="image" />
            </div>
            <div id="procTileDesc" class="tile-desc" [class.creator-card]="isCreator">
              <div>
                <div id="creatorProcTitle" class="title text_camel text_bold" [class.creator-footer]="isCreator" [innerHTML]="item.homeTitle"></div>
                <div id="creatorProcSubtitle" class="subtitle" [class.creator-footer]="isCreator" [innerHTML]="item.description">
                </div>
              </div>
              <span *ngIf="!disableProcessorsFacttag" [ngClass]="isCreator ? 'chevron_right_ftag_white':'chevron_right_ftag'"></span>
            </div>
          </div>
        </div>
        <!-- chromebook -->
            <div id="chromebookTile" *ngIf="isChromeBook" class="other-tiles">
              <div id="chromebookCard" class="card-columns1">
                <div id="chromebookFactTagMenu" *ngFor="let item of factTagMenu; let i = index" (click)="gotoDetailPage(item.name, i)">
                  <div id="endlessProgramCard" class="card" *ngIf="item.homeTitle === endlessProgramsAndAppsValue">
                    <div id="endlessProgramCardBody" class="card-body">
                      <img src="{{item.imageURL}}" alt="image" /> <!-- IIT2-2221 --> 
                    </div>
                    <div id="endlessProgramCardFooter" class="card-footer"> <span class="title text_camel text_bold" [innerHTML]="item.homeTitle"></span>
                      <span class="chevron_right_ftag"></span> 
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="chromebookFacttags" *ngIf="isChromeBook" class="other-tiles chromebook-facttags">
              <div id="chromebookFactTagCard" class="card-columns">
                <div id="chromebookFactTagMenu" *ngFor="let item of factTagMenu; let i = index" (click)="gotoDetailPage(item.name, i)">
                  <div id="factTagCard" class="card" *ngIf="(item.title !== processorValue && item.homeTitle !== endlessProgramsAndAppsValue) &&
                  ((item.description && item.description !== '--') || (item.name === 'alwaysOnSecurity' || item.homeTitle === viewMoreTitle) &&
                  !(item.name === 'price' || item.name === 'operatingSystem') || (!item.description && item.description !== '--' && item.name === 'rethinkStorage'))"> <!--BUg IJR-1779 fix--> <!--IJR-2382 rethink storage-->
                    <div id="blueBgImage" *ngIf="item.title !== viewAllSpecsText" class="card-body blueBg">
                      <img src="{{item.imageURL}}" alt="image" /> <!-- IIT2-2221 --> 
                    </div>
                    <div id="homeCardFooterTitle" class="card-footer">
                      <span class="text_camel" [innerHTML]="item.homeTitle"></span>
                      <span class="chevron_right_ftag"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        <div id="chromebookOtherTiles" *ngIf="!isChromeBook" class="other-tiles">
          <div id="otherTilesCard" class="card-columns">
            <div id="otherTilesFactTagMenu" *ngFor="let item of factTagMenu; let i = index" (click)="gotoDetailPage(item.name, i)">
              <div id="creatorprocValue" class="card" [class.creator-card]="isCreator" *ngIf="item.title !== processorValue">
                <div id="viewAllSpecsText"  *ngIf="item.title !== viewAllSpecsText" class="card-body {{item.name}} {{graphicsColor}} max_{{isIrisMaxPrimary}}" [ngClass]="!isNonIntel && (item.name === 'graphics' || item.name === 'graphicsCard') && isIrisXEType && !isCreator ? 'skyBlueBg': 'blueBg'"
                [class.checkFTag]="isNonIntel">
                  <img *ngIf="!isChromeBook"  [ngClass]="item.name === 'graphics' && !isNonIntel ? 'badge-img graphic_pic': ''"
                    src="{{item?.image ? item.image : item.imageURL}}" alt="image" />
                </div>
                <div id="creatorCardFooter" class="card-footer" [class.creator-card]="isCreator">
                  <span class="text_camel {{item.name}}_transform text_bold" [class.creator-footer]="isCreator" [ngClass]="nonIntelOs === item.title ? 'os_text_trans': ''" [innerHTML]="item.homeTitle"></span> <!-- home page case fix-->
                  <span [ngClass]="isCreator ? 'chevron_right_ftag_white':'chevron_right_ftag'" *ngIf="(!(this.isNonIntel && (item.title === batteryValue || (item.title === displayValue && !isTouchScreen) || (item.title === displayValue && this.isLinux))))"></span>
                </div>
              </div>
            </div>
    
          </div>
        </div>
    
      </div>
    
      <div id="nonIntelContent" class="non_intel_section" *ngIf="isNonIntel" [ngClass]="isNonIntel?'nonIntel':''">
        <div id="nonIntelOtherTiles" class="other-tiles">
          <div id="nonIntelCard" class="card-columns">
            <div id="nonIntelFactTagItem" *ngFor="let item of newFactTagsItem; let i = index" (click)="gotoDetailPage(item.name, i)">
              <div id="nonIntelProcValue" class="card" *ngIf="item.title !== processorValue">
                <div id="nonIntelcardFooter" class="card-footer">
                  <span class="text_camel item_title {{item.name}}_transform" [ngClass]="item.title === nonIntelOs ? 'os_text_trans': ''" [innerHTML]="item.homeTitle"></span>
                  <span class="chevron_right_ftag" *ngIf="(!(this.isNonIntel && (item.title === batteryValue || (item.title === displayValue && !isTouchScreen)|| (item.title === displayValue && isLinux))))"></span>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>
    </ng-container>
    <!-- Added new cpmponant as a part of IJR-1734 12th genGaming -->
    
    <ng-container *ngIf="isGen12Or11">
      <app-tile-content ></app-tile-content> <!-- IJR-1735 -->
      <div id="nonIntelLegalText" class="legal-text" [class.legal_text_buy_now]="buyNowSiteLink" [class.chromebook-legal-text]="isChromeBook" [class.std12_text]="isStandardEnhancement" [class.ai-ipos-text]="isStdMtl"
        [class.legalText_nonintel]="isNonIntel">
        <span class="legal-text-decoration" (click)="open(legalModal)" aria-label="LegalDocument" data-toggle="modal"
          data-target="#legalTextModal" [innerHTML]="legalText">
        </span>
        <span [innerHTML]="legalVersion"></span>
      </div>
      <button *ngIf="!isAiIpos" class="btn scan-icon-button btn-block" [ngClass]="[isNonIntel ? 'compareBtn_Display nonIntel': '',scanBtnBorderColor?'scan-border-dark':'scan-border-light', buyNowSiteLink ? 'scan-btn-transparent':'']"
        (click)="gotoComparePage()">
        <svg width="25" height="24" viewBox="0 0 25 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M0.5 0H5.68343V1.84615H2.34615V5.18343H0.5V0ZM24.5 0H19.3166V1.84615H22.6538V5.18343H24.5V0ZM5.68343 24H0.5V18.8166H2.34615V22.1538H5.68343V24ZM24.5 24H19.3166V22.1538H22.6538V18.8166H24.5V24ZM11.2691 3.69231H4.19231V10.7691H11.2691V3.69231ZM6.03846 8.92298V5.53846H9.42298V8.92298H6.03846ZM4.19231 13.1079H11.2691V20.1847H4.19231V13.1079ZM6.03846 14.954V18.3385H9.42298V14.954H6.03846ZM20.6838 3.69231H13.607V10.7691H20.6838V3.69231ZM15.4531 8.92298V5.53846H18.8376V8.92298H15.4531ZM13.4844 12.9844H15.9254V15.4254H13.4844V12.9844ZM20.8074 15.4255H15.9255V17.8665H18.3666V20.3077H20.8076V16.6462H20.8074V15.4255ZM15.9254 17.8666H13.4844V20.3076H15.9254V17.8666Z" />
        </svg>&nbsp;
        <span [innerHTML]="scanToCompareText"></span>
        <!--GLS-306582 -->
      </button>
      <button *ngIf="buyNowSiteLink && !isAiIpos" class="btn buy-icon-button btn-block"  (click)="goToProductURL()"
        [ngClass]="isNonIntel ? 'compareBtn_Display nonIntel': ''">
        <img class="buy-now-icon" alt ="buy-now">&nbsp;<span [innerHTML]="buyNowText"></span>
        <!--GLS-306582 -->
      </button>
    </ng-container>
    <ng-container *ngIf="!isGen12Or11">
      <div *ngIf="buyNowSiteLink && !isChromeBook" [class.legal_text_buy_now]="buyNowSiteLink" id="nonIntelLegalTextModal" class="legal-text"  [class.legalText_nonintel]="isNonIntel" [class.chromebook-legal-text]="isChromeBook">
        <span class="legal-text-decoration" (click)="open(legalModal)" aria-label="LegalDocument" data-toggle="modal"
          data-target="#legalTextModal" [innerHTML]="legalText">
        </span>
        <span [innerHTML]="legalVersion"></span>
      </div>
    <button *ngIf="!isAiIpos" class="btn scan-icon-button btn-block" [ngClass]="[isNonIntel ? 'compareBtn_Display nonIntel': '',scanBtnBorderColor?'scan-border-dark':'scan-border-light', buyNowSiteLink ? 'scan-btn-transparent':'', isChromeBook ? 'scan-btn-chromebook':'']" (click)="gotoComparePage()">
      <svg width="25" height="24" viewBox="0 0 25 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M0.5 0H5.68343V1.84615H2.34615V5.18343H0.5V0ZM24.5 0H19.3166V1.84615H22.6538V5.18343H24.5V0ZM5.68343 24H0.5V18.8166H2.34615V22.1538H5.68343V24ZM24.5 24H19.3166V22.1538H22.6538V18.8166H24.5V24ZM11.2691 3.69231H4.19231V10.7691H11.2691V3.69231ZM6.03846 8.92298V5.53846H9.42298V8.92298H6.03846ZM4.19231 13.1079H11.2691V20.1847H4.19231V13.1079ZM6.03846 14.954V18.3385H9.42298V14.954H6.03846ZM20.6838 3.69231H13.607V10.7691H20.6838V3.69231ZM15.4531 8.92298V5.53846H18.8376V8.92298H15.4531ZM13.4844 12.9844H15.9254V15.4254H13.4844V12.9844ZM20.8074 15.4255H15.9255V17.8665H18.3666V20.3077H20.8076V16.6462H20.8074V15.4255ZM15.9254 17.8666H13.4844V20.3076H15.9254V17.8666Z" />
      </svg>&nbsp;
      <span [innerHTML]="scanToCompareText"></span>  <!--GLS-306582 -->
    </button>
    <button *ngIf="buyNowSiteLink && !isAiIpos" class="btn buy-icon-button btn-block" (click)="goToProductURL()"
        [ngClass]="isNonIntel ? 'compareBtn_Display nonIntel': ''">
        <img class="buy-now-icon" alt="buy-now">&nbsp;<span [innerHTML]="buyNowText" ></span>
        <!--GLS-306582 -->
    </button>
      <div *ngIf="!buyNowSiteLink || isChromeBook" id="nonIntelLegalTextModal" class="legal-text" [class.legalText_nonintel]="isNonIntel" [class.chromebook-legal-text]="isChromeBook">
        <span class="legal-text-decoration" (click)="open(legalModal)" aria-label="LegalDocument" data-toggle="modal"
          data-target="#legalTextModal" [innerHTML]="legalText">
        </span>
        <span [innerHTML]="legalVersion"></span>
      </div>
    </ng-container>
  </div>
  <app-navigation-bar *ngIf="isAiIpos"></app-navigation-bar>
  <img hidden src="{{storeLogo}}" alt="store logo" (load)="checkStoreLogoLoaded()" (error)="checkStoreLogo()">
  <div id="chromebookFooterInfo" class="footer" *ngIf="!isStoreLogo || price || (buyNowSiteLink && isAiIpos)" [class.chromebook-footer]="isChromeBook" [class.center-align]="(isAiIpos &&((!isStoreLogo && !price && !buyNowSiteLink) || (isStoreLogo && price && !buyNowSiteLink)  || (isStoreLogo && !price && buyNowSiteLink)))" [class.enhancement-footer]="isGen12Or11">
    <span *ngIf="!isStoreLogo"> <!--UAT-563-->
      <img class="storeImg" src="{{storeLogo}}" alt="store logo" onerror="this.style.display='none'"/>
    </span>
    <div class="separator-line" *ngIf="!isStoreLogo && price && buyNowSiteLink && isAiIpos"></div>
    <div class="footer_price" [class.price_new]= "isAiIpos" [class.intelOne-display]="setFontIntelDisplayOne" *ngIf="price">{{price}}</div>
    <button *ngIf="buyNowSiteLink && isAiIpos" class="btn buy-icon-btn-Ai btn-block" (click)="goToProductURL()"
        [ngClass]="isNonIntel ? 'compareBtn_Display nonIntel': ''" [class.center-align-footer]="(isStoreLogo && !price && buyNowSiteLink)">
        <img class="buy-now-icon_Ai" alt="buy-now">&nbsp;<span [innerHTML]="buyNowText" ></span>
    </button>
  </div>
</div>

<ng-template #legalModal let-modal 
    tabindex="-1"
    role="dialog"
    aria-labelledby="legalTextModalLabel"
    aria-hidden="true">
  <div id="rtlDirection" [dir]="direction" [ngStyle]="{'text-align':(direction === 'rtl')? 'right' : ''}" [ngClass]="setFont()" [class.creator-legal]="isCreator" [class.nonIntel]="isNonIntel">
  <button data-dismiss="modal" class="close" (click)="close()">
    <img
      aria-hidden="true"
      class="close-icon"
      alt="close"
    />
  </button>
  <div id="modalHeaderLegalText" class="modal-header">
    <h2 class="modal-title" id="legalTextModalLabel" [class.intelOne-display]="setFontIntelDisplayOne" [innerHTML]="legalText"></h2>
  </div>
  <div id="modalBodyLegalContent" class="modal-body" >
    <p *ngFor='let value of legalContent' [innerHTML]="value">
    </p>
  </div>
</div>
</ng-template> 

<ng-template #buynowmodal *ngIf="!buyNowSiteLink" class="buynow-modal" let-modal tabindex="-1" role="dialog" aria-labelledby="legalTextModalLabel" aria-hidden="true">
  <div id="rtlDirection" class="buynow-popup" [dir]="direction" [ngStyle]="{'text-align':(direction === 'rtl')? 'right' : ''}"
   ><button data-dismiss="modal" class="close" (click)="close()">
      <img aria-hidden="true" class="close-icon" alt="close" />
    </button>
    <div id="modalHeaderBuyNow" class="modal-header modal-buy-now">
      <img aria-hidden="true" class="try-icon" alt="tryagain" />
    </div>
    <div id="modalBodyBuyNow" class="modal-body">
      <p [innerHTML]="buyNowErrText">
      </p>
    </div>
    <button class="tryagain center" (click)="tryagain()" [innerHTML]="tryagainText">     
    </button>
  </div>
 

</ng-template> 




