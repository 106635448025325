
<div  class="nav-buttons" >    
    <button *ngFor="let item of navigationInfo; let i = index" (click)="goToDetailPage(item.targetURL, i)" [ngClass]="index==i ? 'active' : 'inactive'"  [class.compare-flex]="item.targetURL =='compare'" >
        <svg *ngIf="item.targetURL =='compare'" width="25" height="24" viewBox="0 0 25 24" fill="#ffffff" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M0.5 0H5.68343V1.84615H2.34615V5.18343H0.5V0ZM24.5 0H19.3166V1.84615H22.6538V5.18343H24.5V0ZM5.68343 24H0.5V18.8166H2.34615V22.1538H5.68343V24ZM24.5 24H19.3166V22.1538H22.6538V18.8166H24.5V24ZM11.2691 3.69231H4.19231V10.7691H11.2691V3.69231ZM6.03846 8.92298V5.53846H9.42298V8.92298H6.03846ZM4.19231 13.1079H11.2691V20.1847H4.19231V13.1079ZM6.03846 14.954V18.3385H9.42298V14.954H6.03846ZM20.6838 3.69231H13.607V10.7691H20.6838V3.69231ZM15.4531 8.92298V5.53846H18.8376V8.92298H15.4531ZM13.4844 12.9844H15.9254V15.4254H13.4844V12.9844ZM20.8074 15.4255H15.9255V17.8665H18.3666V20.3077H20.8076V16.6462H20.8074V15.4255ZM15.9254 17.8666H13.4844V20.3076H15.9254V17.8666Z" />
          </svg>&nbsp;
          <img  *ngIf="item.targetURL !='compare'" src="{{item.iconURL}}" alt="nav icon"/>
          <div class="nav-title" [innerHTML]="item.tr_specTitle"></div>
    </button>
</div>