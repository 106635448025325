import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { QrCodeService } from '../../core/services/qr-input-service/qr-input.service';
import { LibraryV2DataService } from '../../core/services/library-v2/library-v2-data.service';
import { DetailsService } from '../details-page/services/details.service';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject } from 'rxjs/internal/Subject';
import { GlobalConstants } from '../../common/constants/global.constant';
import { MessageService } from '../../core/services/message-service/message.service';
import { PcSpecsService } from '../details-page/services/pc-specs/pc-specs.service';
import { manufacturerMapping } from '../../common/constants/qrCodeMapping';
import { GoogleAnalyticsService } from '../../core/services/google-analytics-service/google-analytics.service';
declare let gtag: Function;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit, OnDestroy {
  @Input() headerData;
  libraryData = this.libraryServiceData?.getLibraryV2Data(this.qrService.qrInput.Language);
  pageName;
  checked;
  deviceDetails;
  headerDetails;
  mobileDetails;
  qrList;
  isMainLayout = true;
  displayPageName: string;
  disable;
  previewPageUrl: string;
  isNonIntel = false;
  direction: string;
  setFontIntelDisplayOne = false;
  @Output() backToHomePage = new EventEmitter<string>();
  @Output() backtoPreviousPage = new EventEmitter<string>();
  @Output() detailScan = new EventEmitter<{ chatbot, pcspecs }>();
  isChromeBook: boolean;
  private ngUnsubscribe = new Subject<boolean>();
  isNewHeader = false; // changed for 12th gen standard
  homePagecount = 0;
  previousPage = [];
  isStandardEnhancement = false;
  is11GenCreator = false;
  contentType: string;
  isEvo = false;
  isEvoMTL= false;
  isAiIpos = false;

  constructor(
    public router: Router,
    private libraryServiceData: LibraryV2DataService,
    private qrService: QrCodeService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private detailsService: DetailsService,
    private messageService: MessageService,
    private pcSpecsService: PcSpecsService
    ) { }

  ngOnInit(): void {
    const language = this.qrService.qrInput.Language;
    this.direction = (language === 'ar-sa' || language === 'he') ? 'rtl' : 'ltr';
    this.checked = window.location.pathname === '/remote'? true:false
    this.qrList = this.qrService.getQrData();
    this.isNonIntel = (this.qrList.ContentType === GlobalConstants.NON_INTEL_TEXT) ? true : false;
    this.isChromeBook = this.qrService.isChromeBook;  // II-3382
    this.contentType = this.qrService.qrInput.ContentType;
    this.isEvoMTL = GlobalConstants.EVO_MTL_BADGES?.includes(this.qrService.qrInput.ProcessorBadge);
    this.isAiIpos = this.qrService.isAiIpos();
    if ((this.contentType === GlobalConstants.STANDARDGEN12 ||
      this.contentType === GlobalConstants.STANDARDGEN13 ||
      this.contentType === GlobalConstants.CREATORGEN12 ||
      this.contentType === GlobalConstants.CREATORGEN13 ||
      this.contentType === GlobalConstants.STANDARDGEN14
      ) && !GlobalConstants.STD_MTL_BADGES?.includes(this.qrService?.qrInput?.ProcessorBadge) && !GlobalConstants.STD_LNL_BADGES?.includes(this.qrService?.qrInput?.ProcessorBadge)) {
      this.isStandardEnhancement = true;
    } else if (this.contentType === GlobalConstants.CREATORGEN11) {
      this.is11GenCreator = true;
    } else if (this.contentType === GlobalConstants.EVO) {
      this.isEvo = true;
    }
    this.getDeviceDetails();
    // this.getRemoteDetails();
    // this.getMobileDetails();
    this.pageName = this.headerData;
    if (this.qrService.qrInput.IsConnected === 'n' || !this.qrService.enableRemote){
      this.checked = false;
      this.disable = true;
    }
    // IJR-1734 12th genGaming start
    if (this.contentType === GlobalConstants.GAMINGGEN12 || this.contentType === GlobalConstants.STANDARDGEN12 ||
      this.contentType === GlobalConstants.CREATORGEN11 || this.contentType === GlobalConstants.EVO ||
      this.contentType === GlobalConstants.GAMINGGEN11 || this.contentType === GlobalConstants.STANDARDGEN11 ||
      this.contentType === GlobalConstants.CREATORGEN12 || this.contentType === GlobalConstants.CREATORGEN13 || this.contentType === GlobalConstants.STANDARDGEN13 ||
      this.contentType === GlobalConstants.GAMINGGEN13 || this.contentType === GlobalConstants.GAMINGGEN14 ||
      this.contentType === GlobalConstants.STANDARDGEN14) {
      this.isNewHeader = true;
    }
    // IJR-1734 12th genGaming end
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const currentdetailPage = event.urlAfterRedirects.split('/').pop();
        if (currentdetailPage === GlobalConstants.MOB_CONTENT || currentdetailPage === GlobalConstants.ALL_SPECS){
        this.detailsService.setCurrentPage(currentdetailPage);
        this.isMainLayout = true;
        }
      }
    });

    this.detailsService.isMainLayout
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((info: any)  => {
      setTimeout(() => {
        if (info.length > 0){
          this.isMainLayout = false;
          this.displayPageName = info;
        } else {
          this.isMainLayout = true;
        }
      }, 0);
    });
    this.detailsService.previewPage
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(url => {
      if (url){
        this.previewPageUrl = url;
      }
    });
    if (!this.isNonIntel) {
      this.setFontIntelDisplayOne = this.qrService.setenusLang();
    }
    // IJR - 1757
    let pcSpecs = false; // IIT2-4422
    if (this.contentType === GlobalConstants.GAMINGGEN12 || this.contentType === GlobalConstants.STANDARDGEN12 ||
       this.contentType === GlobalConstants.CREATORGEN11 || this.contentType === GlobalConstants.EVO ||
       this.contentType === GlobalConstants.GAMINGGEN11 || this.contentType === GlobalConstants.STANDARDGEN11 ||
       this.contentType === GlobalConstants.CREATORGEN12 || this.contentType === GlobalConstants.CREATORGEN13 || this.contentType === GlobalConstants.STANDARDGEN13 ||
       this.contentType === GlobalConstants.GAMINGGEN13 || this.contentType === GlobalConstants.GAMINGGEN14 ||
       this.contentType === GlobalConstants.STANDARDGEN14) {
      this.messageService.getHomePageCount.subscribe(data => {
        this.homePagecount = data;
      });
      this.messageService.getPreviousPage.subscribe(data => {
        this.previousPage = data;
      });
      pcSpecs = true; // IIT2-4422
    }
    const chatbot = this.qrService.qrInput.IsChatbotEnabled === 'N' || this.contentType[0] === 'C'; // IIT2-4418
    this.detailScan.emit({ chatbot: chatbot, pcspecs: pcSpecs });
  }

  loadTutorial(): void {
    if (this.qrService.qrInput.IsConnected === 'y' && this.qrService.qrInput.ContentType !== 'NI' && this.checked) {
    this.router.navigateByUrl('/tutorial');
    }
    else {
      this.router.navigateByUrl('/offline-tutorial');
    }
  }

  goToOffline (): void {
    this.router.navigateByUrl('/mob-content')
  }

  switchPage(): void {
    this.checked = (!this.checked);
    if (this.checked) {
      this.pageName = this.mobileDetails.specTitle;
      this.router.navigate(['remote']);
    }
    else {
      if(this.disable){
        this.router.navigate(['mob-content']);
      }
      else{
        this.pageName = this.headerDetails.specTitle;
        this.router.navigate(['mob-content']);
      }
    }
  }
  getDeviceDetails(): void{
    let oemBadge = '';
    const isStdOemBadge: boolean = this.isChromeBook || this.isStandardEnhancement;
    const systemManufacturer = manufacturerMapping[this.qrService?.qrInput?.SystemManufacturer];
    if (systemManufacturer) {
      oemBadge = isStdOemBadge ? systemManufacturer : systemManufacturer + GlobalConstants.UNDERSCORE_WHITE;
    }
    this.deviceDetails = {
      specTitle: this.qrService.qrInput.DeviceName,
      deviceLogo: oemBadge
    };
  }

  getRemoteDetails(): void{
    const headerDetail = this.libraryServiceData.getHeaders('remote');
    this.headerDetails = {
      specTitle: headerDetail.tr_specTitle,
      iconURL : headerDetail.iconURL
    };
  }

  getMobileDetails(): void{
    const mobileDetails = this.libraryServiceData.getHeaders('mobile');
    this.mobileDetails = {
      specTitle: mobileDetails.tr_specTitle,
      iconURL : mobileDetails.iconURL
    }
  }

  closeLayout(): void {
    if (this.previewPageUrl){
      this.detailsService.setHeaderText('');
      this.backToHomePage.emit('visited');
      this.router.navigateByUrl(this.previewPageUrl);
    }else{
      this.detailsService.setHeaderText('');
      this.router.navigateByUrl('/all-specs');
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
}

  goBack(): void {
    this.previousPage.splice(-1, 1);
    let path = GlobalConstants.MOB_CONTENT_PATH;
    if (this.previousPage.length > 0) {
      path = GlobalConstants.SPECS + this.previousPage[this.previousPage.length - 1];
      this.backtoPreviousPage.emit(this.previousPage[this.previousPage.length - 1]);
    }
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([path]);
    });
    if (this.previousPage.length === 0) {
      this.pcSpecsService.setCurrentHighlightFactTag('');
    } else { // IIT2-6600
      this.pcSpecsService.setCurrentHighlightFactTag(this.previousPage[this.previousPage.length - 1]);
    }
    this.homePagecount -= 1;
    this.messageService.setHomePageCount(this.homePagecount);
    this.checkVisitedPage(this.previousPage);
  }

  goHome(): void {
    this.previousPage = [];
    this.messageService.setPreviousPage(this.previousPage); // IJR-2378
    this.pcSpecsService.setCurrentHighlightFactTag('');
    this.router.navigateByUrl('/mob-content');
    this.googleAnalyticsService.sendGtag(GlobalConstants.HOME_CLICK, { event_category: GlobalConstants.HOME_BUTTON, event_label: 'home', 'home_button': 1 })
    
  }  

  checkVisitedPage(prevPage) {
    const tileList = [GlobalConstants.CONTENT_NAME, GlobalConstants.PRODUCTIVITY_NAME, GlobalConstants.VR_NAME];
    const tileName = prevPage?.length === 1 ? prevPage[0] : '';
    if(prevPage?.length === 1 && tileList.includes(tileName)) {
      this.pcSpecsService.setCurrentHighlightFactTag('');
    }
  }
}
