import { Component } from '@angular/core';
import { Router, Params } from '@angular/router';
import { GlobalConstants } from '../../../common/constants/global.constant';
import { LibraryV2DataService } from '../../../core/services/library-v2/library-v2-data.service';
import { LibraryV2Data, HeaderDetails } from '../../../core/interfaces/library-v2-data.i';
import { GoogleAnalyticsService } from '../../../core/services/google-analytics-service/google-analytics.service';
import { QrCodeService } from '../../../core/services/qr-input-service/qr-input.service';
@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.css']
})
export class NavigationBarComponent {
  navigationInfo: HeaderDetails[];
  index=0;
  params: Params;
  constructor(
    private router: Router,
    private qrService: QrCodeService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private libraryV2DataService: LibraryV2DataService
  ) { }

  ngOnInit(): void { 
    this.navigationInfo = this.libraryV2DataService.getNavigation('navigation');
    if(this.router.url =='/compare'){
      this.index=1;
    }else if(this.router.url =='/trending'){
      this.index=2;
    }    
  }
  goToDetailPage(path: string, ind:number) {
    this.index=ind;
    const url = this.qrService.getInitialScannedUrl();
    this.params = {};
    this.params = {
      'rt': url
    } as Params;
    this.qrService.setQrDataFromParams(this.params, GlobalConstants.DETAILS_SCAN);
    this.router.navigate([path]);    
  }
}
