import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { LibraryV2DataService } from '../../../core/services/library-v2/library-v2-data.service';
import { GlobalConstants } from '../../../common/constants/global.constant';
import { PcSpecsService } from '../../details-page/services/pc-specs/pc-specs.service';
import { FactTag } from '../../../core/interfaces/fact-tag.i';
import { Router, ActivatedRoute} from '@angular/router';
import { QrCodeService } from '../../../core/services/qr-input-service/qr-input.service';
import { Subject } from 'rxjs/internal/Subject';
import { ResetBotService } from '../../../chatbot/services/resetBot.service';

@Component({
  selector: 'app-pc-specs',
  templateUrl: './pc-specs.component.html',
  styleUrls: ['./pc-specs.component.scss']
})
export class PcSpecsComponent implements OnInit {
  @Output() pageData: EventEmitter<string> = new EventEmitter<string>();
  highlightFacttag: string;
  homePageCount = 0;
  previousPage = [];
  isStdGen = false;
  is13GenCreator = false;
  private ngUnsubscribe = new Subject<boolean>();
  pcSpcesData: FactTag[];
  isAppGaming: any;
  isHomePage: boolean;
  isAiIpos: boolean;
  @Input() getPreviousPageData; 

  constructor(
    private libraryV2DataService: LibraryV2DataService,
    private pcSpecsService: PcSpecsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private qrService: QrCodeService,
    private resetBot: ResetBotService) { }


  ngOnInit(): void {
    // for testing
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    // for testing
    this.pcSpcesData = [];
    const contentType = this.qrService.qrInput.ContentType;
    this.isHomePage = this.router.url.indexOf("mob-content")!=-1;
    this.isAiIpos = this.qrService.isAiIpos();
    if (contentType === GlobalConstants.STANDARDGEN12 || contentType === GlobalConstants.EVO ||
        contentType === GlobalConstants.STANDARDGEN11 || contentType === GlobalConstants.STANDARDGEN13 || contentType === GlobalConstants.STANDARDGEN14 ) {
      this.isStdGen = true;
    }
    else if (contentType === GlobalConstants.CREATORGEN13) {
      this.is13GenCreator = true;
    }
    this.pcSpecsService.getCurrentHighlightFactTag.subscribe(parameter => {
      this.highlightPcSpecs(parameter);
    })

    this.pcSpecsService.pcSpecsData.subscribe(data => {
      data.forEach((value, key) => {
        if (value.title === this.libraryV2DataService.getStaticText('tr_memoryTitle')) {
          value.imageURL = this.isStdGen ? GlobalConstants.STD_PC_SPECS_MEMORY_ICON : GlobalConstants.PC_SPECS_MOMORY_ICON;
        } else if (value.title === this.libraryV2DataService.getStaticText('tr_batteryTitle')) {
          value.imageURL = this.isStdGen ? GlobalConstants.STD_PC_SPECS_BATTERY_ICON : GlobalConstants.PC_SPECS_BATTERY_ICON;
        } else if (value.title === this.libraryV2DataService.getStaticText('tr_displayTitle')) {
          value.imageURL = this.isStdGen ? GlobalConstants.STD_PC_SPECS_DISPLAY_ICON : GlobalConstants.PC_SPECS_DISPLAY_ICON;
        } else if (value.title === this.libraryV2DataService.getStaticText('tr_operatingSystemTitle')) {
          value.imageURL = this.isStdGen ? GlobalConstants.STD_PC_SPECS_OS_ICON : GlobalConstants.PC_SPECS_OS_ICON;
        } else if (value.title === this.libraryV2DataService.getStaticText('tr_storageTitle')) {
          value.imageURL = this.isStdGen ? GlobalConstants.STD_PC_SPECS_STORAGE_ICON : GlobalConstants.PC_SPECS_STORAGE_ICON;
        } else if (value.title === this.libraryV2DataService.getStaticText('tr_connectivityTitle')) {
          value.imageURL = this.isStdGen ? GlobalConstants.STD_PC_SPECS_CONNECT_ICON : GlobalConstants.PC_SPECS_CONNECTIVITY_ICON;
        } else if (value.title === this.libraryV2DataService.getStaticText('tr_wifiTitle')) {
          value.imageURL = this.isStdGen ? GlobalConstants.STD_PC_SPECS_WIFI_ICON : GlobalConstants.PC_SPECS_WIFI_ICON;
        } else if (value.title === this.libraryV2DataService.getStaticText(GlobalConstants.tr_samsung_oled)) {
          value.imageURL = GlobalConstants.SAMSUNG_OLED_ICON;          
        } else if (value.title === this.libraryV2DataService.getStaticText('tr_IntelOptaneMemory')) {
          value.homeTitle = this.libraryV2DataService.getStaticText('tr_optaneMemory'); // IJR-2252
          value.imageURL = GlobalConstants.PC_SPECS_OPTANE_MEMORY_ICON;
        } else if (value.title === this.libraryV2DataService.getStaticText('tr_graphicTitle') || this.libraryV2DataService.getStaticText('tr_graphicsCardCaps')) {
          if (this.isStdGen ) {
            value.imageURL = (value.imageURL === GlobalConstants.GRAPHICS_NONIRIX_ICON) ?
              GlobalConstants.STD_PC_SPECS_GRAPHICS_ICON : value.imageURL;
          }
          else {
            value.imageURL = (value.imageURL === GlobalConstants.GRAPHICS_NONIRIX_ICON) ?
              GlobalConstants.PC_SPECS_GRAPHICS_ICON : value.imageURL;
          }
        } 
               
        if (value.title === GlobalConstants.DAS_TITLE) { //IIT2-3465
          if (this.isStdGen && !this.isAppGaming) {
            value.imageURL = GlobalConstants.PC_SPECS_NON_GAMING_DAS;
          } else {
            value.imageURL = GlobalConstants.PC_SPECS_DAS_ICON;
          }
        }
      });
      this.pcSpcesData = data;
    });
    this.pcSpecsService.previousPageDetail();
  }

  gotoDetailPage(factTagValue): void {
    this.highlightFacttag = factTagValue;
    this.pcSpecsService.setCurrentHighlightFactTag(factTagValue);
    this.pcSpecsService.gotoDetailPage(factTagValue, GlobalConstants.FACTTAG); // calling common service
    this.highlightPcSpecs(factTagValue);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onScroll(event): void {    
    if(this.router.url.indexOf("mob-content")!=-1){
    this.resetBot.resetBotIcon(true);
    }
  }

  ngOnChanges() {
    if (this.getPreviousPageData) {
      this.highlightPcSpecs(this.getPreviousPageData);
    }
  }

  highlightPcSpecs(factTagName) {
    this.highlightFacttag = factTagName;
    setTimeout(() => {
      if (document.querySelector<HTMLElement>(".tag_" + factTagName) !== null) {
        const fromLeft = document.querySelector<HTMLElement>(".tag_" + factTagName).offsetLeft;
        document.getElementsByClassName('pcSpecsScroll')[0].scrollLeft = fromLeft - 35;
      }
    }, 0);
  }
}
